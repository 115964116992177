import {
  BLOG_1,
  BLOG_2,
  BLOG_3,
  BLOG_4,
  BLOG_5,
  BLOG_6,
  BLOG_7,
  BLOG_8,
  BLOG_9,
  BLOG_10,
  BLOG_11,
  BLOG_12,
  BLOG_13,
  BLOG_14,
  BLOG_COVER_6,
  BLOG_COVER_7,
  BLOG_COVER_8,
  BLOG_COVER_12,
  BLOG_COVER_13,
  BLOG_COVER_14,
  CASE_STUDY_1,
  CASE_STUDY_2,
  CASE_STUDY_3,
  CASE_STUDY_4,
  CASE_STUDY_5,
  CASE_STUDY_6,
  CASE_STUDY_7,
  CASE_STUDY_8,
  CASE_STUDY_9,
  SUNDAY_DIGEST_1,
  SUNDAY_DIGEST_1_COVER,
  SUNDAY_DIGEST_2,
  SUNDAY_DIGEST_2_COVER,
} from "../../../shared/images/publicationImages";

export const Blogs = [
  {
    id: 1,
    title: "Aspire Blog: The Art of Learning to Learn- A Valuable Life Lesson",
    url: "the-art-of-learning-to-learn",
    image: BLOG_1,
    cover: BLOG_1,
    description: "The Art of Learning to Learn – A Valuable Life Lesson",
    backgroundColor: "#edd107",
    color: "black",
    author: "Ravi Pattamatta",
    date: "2021-03-12T06:53:03.999Z",
    category: "category",
  },
  {
    id: 2,
    title: "Aspire Blog: Life Lessons from an IT Veteran",
    url: "life-lessons-from-an-IT-veteran",
    image: BLOG_2,
    cover: BLOG_2,
    description: "Life Lessons from an IT Veteran",
    backgroundColor: "#edd107",
    color: "black",
    author: "Amit Sahoo",
    date: "2021-03-30T06:53:03.999Z",
    category: "category",
  },
  {
    id: 3,
    title: "Aspire Blog: Campus Hiring- Then and Now",
    url: "campus-hiring-then-and-now",
    image: BLOG_4,
    cover: BLOG_4,
    description: "Campus Hiring: Then and Now",
    backgroundColor: "#edd107",
    color: "black",
    author: "Amit Sahoo",
    date: "2021-04-07T06:53:03.999Z",
    category: "category",
  },
  {
    id: 4,
    title: "Aspire Blog: The Importance of Belonging",
    url: "the-importance-of-belonging",
    image: BLOG_3,
    cover: BLOG_3,
    description: "The Importance of Belonging",
    backgroundColor: "#edd107",
    color: "black",
    author: "Betty Patnaik",
    // date: "2021-03-31T06:53:03.999Z",
    category: "category",
  },
  {
    id: 5,
    title: "Aspire Blog: 5 things to do to secure a successful future",
    url: "top-5-things-to-do-to-secure-a-successful-future",
    image: BLOG_5,
    cover: BLOG_5,
    description: "Top 5 things to Do to Secure A Successful Future",
    backgroundColor: "#edd107",
    color: "black",
    author: "Amit Sahoo",
    // date: "2020-12-08T06:53:03.999Z",
    category: "category",
  },
  {
    id: 6,
    title: "A Day in the life of a Data Scientist",
    url: "a-day-in-the-life-of-a-data-scientist",
    image: BLOG_6,
    cover: BLOG_COVER_6,
    description: "A Day in the life of a Data Scientist",
    backgroundColor: "#edd107",
    color: "black",
    author: "Santoshi Rakshita",
    date: "2022-02-25T00:00:00.000Z",
    category: "category",
  },
  {
    id: 7,
    title:
      "Opting to Become a Full Stack Developer- What to Expect and How to Prepare",
    url: "opting-to-become-a-full-stack-developer",
    image: BLOG_7,
    cover: BLOG_COVER_7,
    description:
      "Opting to Become a Full Stack Developer- What to Expect and How to Prepare",
    backgroundColor: "#edd107",
    color: "black",
    author: "Prateek Mishra",
    date: "2022-03-01T00:00:00.000Z",
    category: "category",
  },
  {
    id: 8,
    title:
      "Kickstarting your career the right way: 7 lessons you’ll cherish all your life",
    url: "kickstarting-your-career-the-right-way",
    image: BLOG_8,
    cover: BLOG_COVER_8,
    description:
      "Kickstarting your career the right way: 7 lessons you’ll cherish all your life",
    backgroundColor: "#edd107",
    color: "black",
    author: "Isha Gupta",
    date: "2022-03-10T00:00:00.000Z",
    category: "category",
  },
  {
    id: 9,
    title: "Navigating Through an Uncertain Job Market",
    url: "navigating-through-job-market",
    image: BLOG_9,
    cover: BLOG_9,
    description: "Navigating Through an Uncertain Job Market",
    backgroundColor: "#edd107",
    color: "black",
    author: "Isha Gupta",
    date: "2022-03-10T00:00:00.000Z",
    category: "category",
  },
  {
    id: 10,
    title: "Checklist for a Well-Written Resume",
    url: "checklist-for-well-written-resume",
    image: BLOG_10,
    cover: BLOG_10,
    description: "Checklist for a Well-Written Resume",
    backgroundColor: "#edd107",
    color: "black",
    author: "Isha Gupta",
    date: "2022-02-07T00:00:00.000Z",
    category: "category",
  },
  {
    id: 11,
    title: "The Impact of AI and ML on Hiring Practices",
    url: "impact-ai-ml-hiring",
    image: BLOG_11,
    cover: BLOG_11,
    description: "The Impact of AI and ML on Hiring Practices",
    backgroundColor: "#edd107",
    color: "black",
    author: "Isha Gupta",
    date: "2022-03-10T00:00:00.000Z",
    category: "category",
  },
  {
    id: 12,
    title:
      "Riding the Wave: Top Emerging Trends in Engineering and Technology in 2024",
    url: "riding-the-wave",
    image: BLOG_12,
    cover: BLOG_COVER_12,
    description:
      "Riding the Wave: Top Emerging Trends in Engineering and Technology in 2024",
    backgroundColor: "#edd107",
    color: "black",
    author: "Isha Gupta",
    date: "2024-04-26T00:00:00.000Z",
    category: "category",
  },
  {
    id: 13,
    title: "Navigating the Job Market Storm: A Guide for Engineering Students",
    url: "navigating-the-job-market-storm",
    image: BLOG_13,
    cover: BLOG_COVER_13,
    description:
      "Navigating the Job Market Storm: A Guide for Engineering Students",
    backgroundColor: "#edd107",
    color: "black",
    author: "Isha Gupta",
    date: "2024-04-26T00:00:00.000Z",
    category: "category",
  },
  {
    id: 14,
    title:
      "Gender Diversity in the Indian Technology Sector and How We Are Doing Our Bit to Fix It",
    url: "gender-diversity-in-the-indian-technology-sector",
    image: BLOG_14,
    cover: BLOG_COVER_14,
    description:
      "Gender Diversity in the Indian Technology Sector and How We Are Doing Our Bit to Fix It",
    backgroundColor: "#edd107",
    color: "black",
    author: "Isha Gupta",
    date: "2024-04-26T00:00:00.000Z",
    category: "category",
  },
];

export const CaseStudies = [
  {
    id: 1,
    image: CASE_STUDY_1,
    alt: "Aspire Case Study: Building a Solid Product Development Team for Innominds",
    url: "building-a-solid-product-development-team-for-innominds",
    description:
      "Building a Solid Product Development Team for Innominds- One of the Best Places to Work for",
    backgroundColor: "#e6e6e6",
    color: "black",
  },
  {
    id: 2,
    image: CASE_STUDY_2,
    alt: "Aspire Case Study: Building a Large Business Intelligence Team for a Fortune 100 IT Services Organization",
    url: "building-a-large-business-intelligence-team",
    description:
      "Building a large Business Intelligence team for a Fortune 100 IT services organization",
    backgroundColor: "#edd107",
    color: "black",
  },
  {
    id: 3,
    image: CASE_STUDY_3,
    alt: "Aspire Case Study: HAPPILY HEALTH",
    url: "happily-health",
    description:
      "HAPPILY HEALTH - Setting Up The Team That Built The Best Health App",
    backgroundColor: "#edd107",
    color: "black",
  },
  {
    id: 4,
    image: CASE_STUDY_4,
    alt: "Aspire Case Study: Empowering Entry-Level Engineers with Data",
    url: "empowering-entry-level-engineers-with-data",
    description:
      "Empowering Entry-Level Engineers with Data-Driven MERN Stack Training",
    backgroundColor: "#edd107",
    color: "black",
  },
  {
    id: 5,
    image: CASE_STUDY_5,
    alt: "Aspire Case Study: Building a Solid Product Development Team for a Leading Digital Transformation",
    url: "building-a-solid-product-development-team-for-leading-digital-transformation",
    description:
      "Building a Solid Product Development Team for a Leading Digital Transformation and Product Engineering company",
    backgroundColor: "#edd107",
    color: "black",
  },
  {
    id: 6,
    image: CASE_STUDY_6,
    alt: "Aspire Case Study: Setting Up the India Tech Team for the Largest Global Data Center",
    url: "setting-up-the-india-tech-team-for-the-largest-global-data-center",
    description:
      "Setting Up the India Tech Team for the Largest Global Data Center & Colocation Provider for Enterprise Networks & Cloud Computing",
    backgroundColor: "#edd107",
    color: "black",
  },
  {
    id: 7,
    image: CASE_STUDY_7,
    alt: "Aspire Case Study: Partnering with the Global Leader in Next Generation Digital Services",
    url: "partnering-with-the-global-leader-in-next-generation-digital-services",
    description:
      "Partnering with the Global Leader in Next Generation Digital Services and Consulting to Drive their Initiative of Raising the Quality of Tech Education in India",
    backgroundColor: "#edd107",
    color: "black",
  },
  {
    id: 8,
    image: CASE_STUDY_8,
    alt: "Aspire Case Study: Conceptualizing and Running a Back-to-Work Program to Contribute to the Gender Diversity Goals",
    url: "conceptualizing-and-running-a-back-to-work-program",
    description:
      "Conceptualizing and Running a Back-to-Work Program to Contribute to the Gender Diversity Goals of the World’s Largest Data Center & Colocation Provider for Enterprise Networks & Cloud Computing",
    backgroundColor: "#edd107",
    color: "black",
  },
  {
    id: 9,
    image: CASE_STUDY_9,
    alt: "Aspire Case Study: Collaborating with Engineering Colleges",
    url: "collaborating-with-engineering-colleges",
    description:
      "Collaborating with Engineering Colleges Across Telangana, Andhra Pradesh and Odisha to Develop a Stable Talent Farm for the Largest Tech MNCs in India",
    backgroundColor: "#edd107",
    color: "black",
  },
];
export const NewsLetters = [
  {
    id: 1,
    title: "Sunday Digest- Volume 1",
    alt: "Aspire students are well-rounded talent, and they take to any team, function and challenge they are given. We take immense pride in the career progression of Prudhvi Madasu, who is now senior analyst at the Bank of America. Read on to find out his story. ",
    url: "sunday-digest-volume-1",
    image: SUNDAY_DIGEST_1,
    cover: SUNDAY_DIGEST_1_COVER,
  },
  {
    id: 2,
    title: "Sunday Digest- Volume 2",
    url: "sunday-digest-volume-2",
    image: SUNDAY_DIGEST_2,
    cover: SUNDAY_DIGEST_2_COVER,
  },
];
// export const WhitePapers = [
//   {
//     id: 1,
//     backgroundColor: "#0468bf",
//   },
//   {
//     id: 2,
//     backgroundColor: "#02d2de",
//   },
//   {
//     id: 3,
//     backgroundColor: "#000079",
//   },
// ];

// export const Events = [
//   {
//     id: 1,
//     title: "JavaScript Higher Order Functions & Arrays",
//     video: "https://www.youtube.com/embed/rRgD1yVwIvE",
//     backgroundColor: "#000079",
//     color: "white",
//   },
//   {
//     id: 2,
//     title: "React Material UI Complete Tutorial",
//     video: "https://www.youtube.com/embed/m-2_gb_3L7Q",
//     backgroundColor: "#000079",
//     color: "white",
//   },
//   {
//     id: 3,
//     title: "Full React Course 2020",
//     video: "https://www.youtube.com/embed/4UZrsTqkcW4",
//     backgroundColor: "#000079",
//     color: "white",
//   },
// ];
