export const FACEBOOK_URL =
  "https://www.facebook.com/Aspire-InfoLabs-105959354838807";

export const INSTAGRAM_URL = "https://www.instagram.com/aspireinfolabs/";

export const TWITTER_URL = "https://twitter.com/Aspire_InfoLabs";

export const LINKEDIN_URL = "https://www.linkedin.com/company/aspire-infolabs";

export const YOUTUBE_URL =
  "https://www.youtube.com/channel/UC9ybUWOgKpw1wGaODkDMZBQ";
