import {
  FACEBOOK_ICON,
  TWITTER_ICON,
  LINKEDIN_ICON,
  BLOG_FACEBOOK_ICON,
  BLOG_TWITTER_ICON,
  BLOG_LINKEDIN_ICON,
} from "../../../shared/images/iconsAndShapes";
import {
  FACEBOOK_URL,
  TWITTER_URL,
  LINKEDIN_URL,
} from "../../../../../shared/constants/social.const";
import {
  BLOG_6_IMAGE_1,
  CASE_STUDY_4_IMAGE_1,
  CASE_STUDY_5_IMAGE_1,
  CASE_STUDY_6_IMAGE_1,
  CASE_STUDY_6_IMAGE_2,
  CASE_STUDY_6_IMAGE_3,
  CASE_STUDY_7_IMAGE_1,
  CASE_STUDY_7_IMAGE_2,
  CASE_STUDY_7_IMAGE_3,
  CASE_STUDY_7_IMAGE_4,
  CASE_STUDY_8_IMAGE_1,
  CASE_STUDY_9_IMAGE_1,
} from "../../../shared/images/publicationImages";

export const BlogDetailsData = [
  {
    id: 1,
    authorDetails:
      "This article has been written by Ravi Pattamatta. Ravi is the Co-Founder and COO of GOGestalt Inc.",
    details: [
      {
        id: 1,
        data: "It is often noted that the Indian education system values knowledge far more than the application of knowledge. This has traditionally led to students focusing more on memorizing the concepts, with the exclusive attention being on scoring well in their exams.",
      },
      {
        id: 2,
        data: "The real world of employment, however, focuses on the application of knowledge. To be successful in the real world, one should learn to apply the knowledge they have amassed, and be a continuous learner. In the knowledge economy, we all need to be continuously learning, which can only be achieved if we learn how to learn.",
      },
      {
        id: 3,
        data: "Aspire’s whole hearted focus in the classrooms is to inculcate in our students the application of knowledge as well as the skill of ‘learn to learn’.",
      },
      {
        id: 4,
        data: "“Training on learning to learn is the religious mantra of Aspire. We believe in giving a fishing rod and teaching how to fish rather than give fish.”, asserts Srinivas Patnaik, Co-founder and CEO of Aspire Infolabs.",
      },
      {
        id: 5,
        data: "At Aspire, every candidate goes through a series of exercises to help them unlearn their old habits, pick up a new way of working, internalize the process and make the new process part of the regular work habit. A simple example of a change in process for the candidates is to move to open book exams compared to the traditional model of assessment on memorization. The focus thereof is on application of the concepts and ability to quickly learn how to look for a solution. All through the training, the candidates are encouraged to research and be innovative in guided self-learning.",
      },
      {
        id: 6,
        data: "Our results amply corroborate the value of the methodology of learning to learn. In one of the batches for a Fortune 100 Technology company, Aspire trained the candidates in 4 specific technologies of BI. Within days of the team onboarding, the client got an unplanned demand for talent in a completely different BI product. The Aspire-trained team was able to learn this new technology effectively on their own and be productive within weeks.“We could not have done this but for the training we got on learning to learn” - was the uniform feedback from the candidates. Aspire alumni regularly outperform their college batchmates in career growth and in several cases by an order of magnitude with dream jobs in companies like Apple, Amazon, Dell, Microsoft, etc. Aspire just gave them the fishing rod and taught them how to fish. ",
      },
    ],
  },
  {
    id: 2,
    authorDetails:
      "This blog has been written by Amit Sahoo. Amit has over two decades of experience with global IT majors, and currently manages several large client accounts for Wipro, UK.",
    details: [
      {
        id: 1,
        data: "Friends- I am still very much one amongst you, my ears and eyes are always wide open, and I am keen to learn from everything that I get an opportunity to experience through work or my personal life. There are some lessons that I have held very close to my heart, and I am happy to be able to share them with you. ",
      },
      {
        id: 2,
        data: "The first lesson- Over these years, one thing that has remained constant with me is “humility in learning from whatever I do or like to do”. That is one of the greatest assets for life one can consciously build from very start of the industry career. With this, one automatically becomes open to new ideas or even bring his/her own. This is what matters in developing a growth mindset. This is what every industry, every corporate is looking for when they welcome new aspiring young talents like you to their family.",
      },
      {
        id: 3,
        data: "The second lesson- A critical aspect of the IT services industry, and as a matter of fact for any industry is, how we see, understand, value and serve our customers or clients. These stakeholders always sit at top of the value chain. The global IT industry has recognized the value of “customer is king”, and works whole-heartedly towards ensuring that the entire organization is customer-centric. An obsession, if you will, to do right by the customer, and to go above and beyond to fulfil customer expectations, is needed in every single employee, no matter how young or seasoned they are as professionals, and what role they occupy in the organization structure. Organizations are constantly looking for ways and means to “wow” their customers now, not just meet their expectations.",
      },
      {
        id: 4,
        data: "The IT industry does a business of about $3.5-4 trillion every year. There is a huge opportunity for everyone to contribute and grow with this industry. So, it is absolutely important for each one of us to do what’s right for customer (many a times, even customer’s customers play a pivotal role, bring great influence on everything we do). Always be transparent, bring digital ideas and innovation in everything you do, and lastly, earn customer trust, be part of the customer family. ",
      },
      {
        id: 5,
        data: "Be yourself, win trust.",
      },
    ],
  },
  {
    id: 3,
    details: [
      {
        id: 1,
        data: "Hiring has greatly evolved over the last decade. Recruiters are no longer bound by campus placement seasons, dull CVs, repetitive questions, and monotonous answers. The recruitment process is now smart, goes deep into understanding an applicant’s aptitude and skill sets, along with attitudinal assessments to ensure organizational fit. ",
      },
      {
        id: 2,
        data: "Let’s look at the top three changes in recruitment strategy job applicants need to keep in mind: ",
        list: [
          {
            id: 1,
            text: "AI/ML based analytical tools- Recruiters’ hiring decisions are now guided by facts and figures provided by AI/ML based tools to guide their judgment in cultural, technical and attitudinal fit.  With facial recognition tools, voice assessment capabilities, and highly involved and interactive recruitment exercises such as community competitions, internships, live projects etc, there is a plethora of data available to recruiters, and this has made the process much more accurate as a whole. ",
          },
          {
            id: 2,
            text: "Change in recruiter expectations-Recruiters are increasingly clear in their attitudinal expectations from applicants.  Employees need to be open to learning and adapting quickly to new technologies and new ways of reaching their customers. They need to be customer-centric from day one.  Applicants are also expected to be differentiated and experienced through internships, real world projects, and other practical experiences. Gone are the days where a college degree would land you a job. Applicants need to go above and beyond to showcase their capabilities. ",
          },
          {
            id: 3,
            text: "Recruitment sources- Recruiters are no longer bound by campuses and placement seasons.  With enhanced connectivity and platforms like LinkedIn, Twitter, and even Facebook /Instagram, professional networking has taken on a completely different path. A potential job offer could come from anywhere and at any time. Recruiters could find their next great hire in a Facebook group, or via comments on LinkedIn. Applicants need to be aware and conscious of their digital footprint, and work towards creating a digital brand for themselves. ",
          },
        ],
      },
      {
        id: 3,
        data: "Intelligent hiring requires that students should be sufficiently prepared, to be able to land their dream first jobs. With these insights in their kitty, applicants can be sure of a smooth job hunt and onboarding experience. ",
      },
    ],
  },
  {
    id: 4,
    authorDetails:
      "This article has been written by Betty Patnaik. Betty worked with Aspire as a soft skills trainer and then with Deloitte India. ",
    details: [
      {
        id: 1,
        data: "What does it take to be loyal to an organization, team or a family? While most of us seek only the intrinsic factors that constitute the character of a person, there are many extrinsic ones that influence us too. I am initiating a thread of 5 such determinants in this series- focusing on “Belongingness” first.",
      },
      {
        id: 2,
        data: "Associating with ASPIRE way back in 2006 was not only an exciting experience but one that taught me many crucial aspects of self-development. Little had I known then, that I’d continue this association over a decade and more! As I reminisce these years, the 3 warm office locations bring back happy memories and a smile. The curious, young faces that stare with eagerness and awe – flash by! And I can’t bring my thoughts back to relate, what it takes to “belong”……",
      },
      {
        id: 3,
        data: "A connect request on LinkedIn or bumping onto someone in a public space –struggling to trace the memory, and then a humbling voice that says,” Mam, I was with ASPIRE and you had inducted us “.",
      },
      {
        id: 4,
        data: "That’s the aha moment when you realize the depth of association and the purpose of belonging, with your first organization aka professional family! I recall the induction batches at Volvo, Hurix systems, Zensar and many others that had one thing in common – an invisible bond that we all develop sitting, working, chatting, and eating together! ",
      },
      {
        id: 5,
        data: "The humble, cozy environment created by the organization plays a vital role and so does the personalized care that is provided to each and every individual. I vividly remember Srini, mention the full name of the pupil in a respective batch, the details of family background and other finer nuances that shapes the individuals. I had no idea, why these little details were important then. Fast forward a couple years and I start appreciating the value of personalized care. Yes, as humans we never forget the first impressions and the first experience. With ASPIRE it is this care and authentic leadership, that stays with everyone who connects with it!",
      },
      {
        id: 6,
        data: "Now that I’ve shared, why I belong to ASPIRE, let us delve with authentic leadership skills in the next.",
      },
    ],
  },
  {
    id: 5,
    details: [
      {
        id: 1,
        data: "Here are Aspire’s top 5 commandments for a successful career- ",
        list: [
          {
            id: 1,
            text: "Trust in yourself and be crystal clear on what you want – To achieve anything significant, you have to start with what you want to accomplish and suspend any discussion of how you will do it. That comes later. First, you must be clear on how you envision your future. A stronger and effective vision can be achieved by presenting a positive view of the future and expressing confidence that it will be achieved. If you don’t have faith in yourself, who else will? ",
          },
          {
            id: 2,
            text: "Be a lifelong learner- One must always be open to learning, by not only studying new courses/skills, but also being flexible, and open-minded towards the dynamic nature of the world.  Lifelong learning, whether that is technical skills that help us improve at our jobs, or personal lessons we pick up along the way, can enhance our understanding of the world around us, provide us with better opportunities and improve our quality of life.",
          },
          {
            id: 3,
            text: "Find a mentor- Once we’ve identified a field of our choosing, the next thing to do is network with people who have worked hard and done well in the same field. Learning from someone who has been successful in your field of interest helps ensure you get the right direction and inputs, the right nudges, that put you on the right path, improve your confidence and will often provide you with some great strategies to deal with the challenges you might face along the way.",
          },
          {
            id: 4,
            text: "Give to others – If you give others help or credit, that shows generosity. Not only do people remember you for your goodness, you will also learn a thing or two from the ones you helped. The world will be a better place if more people showed generosity to others. Plus, good deeds always find a way of coming back, right? ",
          },
          {
            id: 5,
            text: "Never lose humility- What good is a ton of success without a humble heart? Humility ensures we are open to learning from our mistakes, and are focused on bettering ourselves. ",
          },
        ],
      },
      {
        id: 2,
        data: "At Aspire, we are proud to coach students who live by these values. Our students have reached the pinnacles of success in organizations of their choice, but remain true to their roots, and contribute not just to the industry but the society at large. Kudos to our students!",
      },
    ],
  },
  {
    id: 6,
    details: [
      {
        id: 1,
        sections: [
          {
            id: 1,
            heading: "Meet Bob – Our Superhero Data Scientist!",
            list: [
              {
                id: 1,
                text: "Bob is the name of our mental framework for an exemplary data scientist. Bob has messy hair, wears round glasses, has intellectual curiosity, and a proactive problem-solving attitude. Bob is the fictional character that we will use as a thinking model in this blog post to understand what does a data scientist do on a day-to-day basis.",
                image1: BLOG_6_IMAGE_1,
              },
            ],
          },
          {
            id: 2,
            heading: "7 AM Wake Up – An Early Riser",
            list: [
              {
                id: 1,
                text: "Bob’s day starts with checking his emails for any alerts on the code that has been in operation through the night and reacts if necessary.",
              },
            ],
          },
          {
            id: 3,
            heading: "8 AM- Head to Work",
            list: [
              {
                id: 1,
                text: "When working on complex data science projects, Bob always has his mind occupied thinking about which machine learning approach would work best for his dataset on his commute to the office. During other days where the data product has already been built and the team is working on code polishing or other temporal activities, he spends his commute time thinking about what be the next version of this product, what can be the improvements in this regard and so on.",
              },
            ],
          },
          {
            id: 4,
            heading: "9 AM- Arrive at Work",
            list: [
              {
                id: 1,
                text: "A busy day starts. Last week Bob’s data science team released a new prediction model. Bob monitor’s the outcome of this model to verify that there have been no surprises or critical issues during the night that need dealing with. He then checks the infrastructure health and data pipeline to ensure everything is working as expected.",
              },
            ],
          },
          {
            id: 5,
            heading: "11 AM- Sprints and Stand-up Meetings",
            list: [
              {
                id: 1,
                text: "Yesterday, Bob had been working on optimizing and tuning the parameters of a decision tree machine learning algorithm. Today, he plans to evaluate the accuracy and variations in predictions of all the tuned parameters. Bob and his team hold meetings with different cross-functional members. They have meetings with various stakeholders, the project managers, marketing, sales, and mention what they’re working on and what they plan to work on next. This helps bob and his team understand their goals and how the data can be leveraged to achieve these goals.",
              },
              {
                id: 2,
                text: "During these meetings, his team also presents the actual details of the project, shares the code and visualizations with each other, and discusses the progress. These meetings are typical task trackers to ensure that everyone in the data science team is on the same page and to ensure that there are no blockers.",
              },
            ],
          },
          {
            id: 6,
            heading: "1 PM- Lunch",
            list: [
              {
                id: 1,
                text: "Lunch is sometimes delayed not because bob is not hungry but sometimes, he gets so busy and engrossed in the code that he has his lunch only after he feels that he has made some progress towards analysis.",
              },
            ],
          },
          {
            id: 7,
            heading: "2 PM – 5 PM Code, Code, and Code",
            list: [
              {
                id: 1,
                text: "Developing a machine learning model requires several days, weeks, or months and there is a step-by- step process every data scientist follows. This is the time when actual requirements gathering, data preparation, exploratory data analysis, feature engineering, and model building takes place. Some days bob is building data cleaning or plumbing services using various data scientist tools like BigML, MATLAB, Tableau etc. and other days he could be doing some academic style-research and coding in Python to implement a machine learning algorithm. He could also be performing analysis in Jupyter notebook using SciKit learn or firing up PyCharm to code up a class that implements a machine learning model or interfaces with the database.",
              },
              {
                id: 2,
                text: "Bob also helps his team members to improve their machine learning models by testing the current model on real data and identifying any false negatives. He also creates new training examples to fix problems if any.",
              },
            ],
          },
          {
            id: 8,
            heading: "6 PM- Code Review",
            list: [
              {
                id: 1,
                text: "Having coded all day long, it’s time to let your peers know about the changes you have committed. Code reviews usually happen over conference calls or through in-person meetings. Bob takes the help of other data scientists in his team when he is stuck with a code snippet and also makes it a point to explain the code to his peers so he knows his code better. The collaboration of new ideas and brainstorming happens in these meetings.",
              },
            ],
          },
          {
            id: 9,
            heading: "7 PM – End of a Hectic Day… Home Time!",
            list: [
              {
                id: 1,
                text: "Bob heads back home but if there is any data science or tech event happening in his company, he makes it a point to attend that so that he can learn new things from brilliant tech minds. Bob likes to unwind in his home, researching on some latest data science trends and technologies to keep himself updated.",
              },
            ],
          },
        ],
      },
      {
        id: 2,
        data: "This is how a typical day in the life of a data scientist looks like. However, this varies from project to project, company to company, and person to person. There are many data science companies and hundreds of data scientists but the data science process and the tasks a data scientist will be involved in remain the same.",
      },
      {
        id: 3,
        data: "A day in the life of a data scientist is similar to the life of a writer who is always immersed in a deep state of concentration. In fact, it is never boring or dull, instead it is full of challenges and opportunities to learn new things and solve new business problems.",
      },
    ],
  },
  {
    id: 7,
    details: [
      {
        id: 1,
        data: "When engineers begin their journey to eventually enter the IT industry, they have many different options available to them. A lot of them are informed by the buzzwords going around at the time. This does explain a lot of the craze revolving around Machine Learning and Artificial Intelligence at this point in time. However, regardless of the time, web technologies have always commanded a lot of interest among engineers. And inside the web technology spectrum, the most coveted role seems to be that of a full-stack engineer. There is always an option for students to either become a specialist in front-end or back-end development, the question that arises here is why go for learning the full-spectrum?",
      },
      {
        id: 2,
        data: "It is all about the value of being a T-shaped person. A T-shaped person is someone who has good knowledge in one area and a base of general knowledge in other supporting areas. And a Full Stack Web Developer fits this description to a T. Being a full stack developer, you are more valuable to a team, as you are able to address both aspects of the web development process. If needs must, they are themselves a one-man team, capable of building applications from the inner mechanisms all the way to the exterior facade.",
      },
      {
        id: 3,
        data: "In a nutshell, one developer who can understand how a website should look and can access, differentiate and manipulate data while understanding the technical limitations of such implementations will be a valuable member of any company.",
      },
      {
        id: 4,
        data: "Why are Full Stack Developers in Demand? Every industry, from start-ups to multinational corporations in businesses, look-out for full-stack developers. They are of value and are highly versatile assets to the organization. Their hold in knowledge, expertise infield, and appropriate technological insight has proven to be highly valuable to organizations. Moreover, the web increasingly acts as the conduit for the majority of the interactions between organizations and their customers now. Therefore, full-stack developers are primarily responsible for the customer experience delivered by the organization. Their knowledge of the front-end and back-end helps them tailor a stellar experience for each application they build, an invaluable trait in today’s software industry.",
      },
      {
        id: 5,
        data: "How to become a full-stack developer?",
      },
      {
        id: 6,
        data: "Even full-stack engineering has a lot of types and variants. I’m sure you must have heard about the different ‘stacks’ of full-stack development. They are basically the acronyms made from the different Front-end libraries, back-end languages, and databases you use. And, in order to become a full-stack developer, you need to master one of each.",
      },
      {
        id: 7,
        data: "That is not all though. Apart from front and back-end development, one needs to have knowledge of various other technologies (for e.g. - Version control Systems, APIs, web services to name a few). There are a few stacks that people use more than most, largely due to the compatibility they share. However, if you have mastered one stack, you shouldn’t face any major problems while experimenting with other stacks as well. Every stack will have some perks in certain situations, disadvantages in other situations. It is important to choose whichever one you are most comfortable with. Because, at the end of the day, you’ll be able to realize your ideas into full-scale applications all the same.",
      },
      {
        id: 8,
        data: "We wish you a fulfilling career as a full stack developer!",
      },
    ],
  },
  {
    id: 8,
    details: [
      {
        id: 1,
        data: "Most students who have graduated college and are stepping into the corporate world, armed with newly gained knowledge and professional training, wish to see their career soar quickly, and be successful and ahead of the competition.",
      },
      {
        id: 2,
        data: "While this is a great dream, it is useful to keep in mind that there are thousands of students in our country setting out on their planned path, and many of them come with great education, internships, references etc. What will help you shine out from the crowd is a focus on the long-term ambition that you seek to achieve, and useful lessons that will help you always take the right decisions.",
      },
      {
        id: 3,
        data: "Our career counselors, IT industry veterans, and trainers have put together a list of guiding principles, as we like to call them at Aspire, for all young students who are setting out to achieve their dreams:",
        list: [
          {
            id: 1,
            text: [
              <b>Plan for your first job:&nbsp;&nbsp;</b>,
              "You must keep in mind that your first job has a significant role to play in the direction your career will take. All future jobs will depend on the experience gained in the first one. It is therefore, important to think through which option you’d like to go for, as against quickly freezing on the very first job offer that comes your way.",
            ],
          },
          {
            id: 2,
            text: [
              <b>Think long term:&nbsp;&nbsp;</b>,
              "Build a career, not just a job. If you’re invested deeply into building your career from scratch, think about how every promotion, every role change, every job shift, will impact the ultimate goal you set out to achieve. Evaluate a job offer not by short term things like salary hike, but their value addition to your ambitions.",
            ],
          },
          {
            id: 3,
            text: [
              <b>Follow your passion, always:&nbsp;&nbsp;</b>,
              "Sometimes, due to family or peer pressure, or a lack of self- discovery, we end up studying a profession that doesn’t seem to be our calling. Its okay. There is always time to course-correct. Think about what you truly love doing, and follow that path. It will lead you to success, happiness, and contentment, no matter what.",
            ],
          },
          {
            id: 4,
            text: [
              <b>Be flexible:&nbsp;&nbsp;</b>,
              "A lot of times, our dream job comes with caveats, such as shifting to a new city. Don’t put any limits for yourself, and go the extra mile to make it work for you.",
            ],
          },
          {
            id: 5,
            text: [
              <b>Be part of a culture that speaks to you:&nbsp;&nbsp;</b>,
              "More often than not, we end up giving more than 8 hours to our jobs, day in and day out. It is therefore very important that we select an organization that has a work culture we are likely to enjoy. Do your research on that, don’t trivialize it.",
            ],
          },
          {
            id: 6,
            text: [
              <b>Never let go of humility:&nbsp;&nbsp;</b>,
              "No matter what college you’ve studied from, what internships you completed, what experience you bring to the table, there will always be someone who has learnt more than you. Humility should be our constant companion. The moment we become arrogant, we stop learning.",
            ],
          },
          {
            id: 7,
            text: [
              <b>Be open to feedback and learning:&nbsp;&nbsp;</b>,
              "In our search for jobs, we sometimes interview with multiple companies. It is important to connect with the recruiters, and ask them for feedback every time you complete an interview. This will help you learn from your errors and improve your performance the next time.",
            ],
          },
        ],
      },
      {
        id: 4,
        data: "These simple tips, if practiced, will guide you and hold you in good stead, not just in your first jobs, but in future career advancements too. Good luck to the new graduates!",
      },
    ],
  },
  {
    id: 9,
    details: [
      {
        id: 1,
        data: "Every day you read the newspaper, or open the news apps on your phone, there is some new article about some large IT leader announcing yet another layoff. Large numbers of employees are losing their cushy tech jobs, and this worrying industry leaders on the whole. How does this impact the overall job market? Is it only a matter of time that the startups will follow the big tech companies? Is there going to be a global blood bath?",
      },
      {
        id: 2,
        data: "Before you let all these things play on your mind, take a step back and be sure to analyze- ",
        list: [
          {
            id: 1,
            text: "Are the layoffs severely impacting your country? Or is the economy more insulated? ",
          },
          {
            id: 2,
            text: "If the doors seem to be closing for the big tech firms, what are the other options available? ",
          },
        ],
      },
      {
        id: 3,
        data: "If you are a student graduating this year in India, breathe. Things aren’t nearly as bad here. Let me explain.",
      },
      {
        id: 4,
        data: "Year 2022 started with the dread of one more Covid-induced lockdown, and the upheavals in the global political and economic environment didn’t smoothen things out either. It has been a rollercoaster ride for companies and talent, who are still navigating through complex work environments like working from home, hybrid work, etc. As the stars of the Silicon Valley started downsizing their workforces after a sudden regularization of demand as the world opened up and got back to normal, we also saw a Domino effect of that on other smaller companies. In India too, many large organizations announced their staff reorganization plans. But what must be noted is that- while some roles became redundant, for some roles and job skills, an enormous potential was noted. Quick research on LinkedIn will show you that many roles for cloud engineering, cybersecurity, AI/ML, data science, UI/UX design, etc. are still very much available.",
      },
      {
        id: 5,
        data: "In fact, many startup Founders are even looking at this as a great opportunity to attract the best talent to their businesses. Many CHROs and CEOs have been quoted saying that oftentimes, the best talent went to the big tech companies, for the brand pull. But now, all this great talent is made available to the startups to absorb. Not shockingly, most Founders have already rolled out some great offers to people who lost their jobs in the past few months. In fact, according to some recent research, 80% of the tech talent who lost their jobs found new roles within three months of starting their job search!",
      },
      {
        id: 6,
        data: "And lastly, let’s not forget that our economy is still growing, and growing fast! We have been quite insulated from the global economic and political downturns. Most importantly, many of our large corporations have also indicated that their hiring plans have always been long term, with a three-year roadmap for any talent hired at the very least, and they didn’t hire by the hoards just to keep up with the Covid-induced temporary demand surge. What does this mean? They don’t really have a lot of extra staff to lay off! That is truly heartening, isn’t it?",
      },
      {
        id: 7,
        data: "So, what can you do? Be smart. Find the startups that excite you. Find Founders whose vision challenges you. Try and work with them! There are plenty of opportunities coming, if we know where to look!",
      },
    ],
  },
  {
    id: 10,
    details: [
      {
        id: 1,
        data: "When you start applying for jobs, the most critical aspect of your application is how well-rounded, impactful, and professional your resume looks. After all, your resume decides whether you will be invited to the first interview or not. It is the first impression you make on a prospective employer. You must pay attention to the details before you submit your resume for any job role.",
      },
      {
        id: 2,
        data: "Here is a quick checklist we have prepared for you to keep in mind:",
        list: [
          {
            id: 1,
            text: [
              <b>Keep it short and clear:&nbsp;&nbsp;</b>,
              "The average recruiter spends no more than 30 seconds to decide if a resume is worth their time. Keep it short and crisp and definitely no more than two pages, to ensure the recruiter focuses on the most important bits of information about you",
            ],
          },
          {
            id: 2,
            text: [
              <b>Quantsify your achievements:&nbsp;&nbsp;</b>,
              "Whatever you have achieved in your professional pursuits so far, be it at an internship, a pro-bono project, or a college assignment, be sure to quantify the impact of your work clearly ",
            ],
          },
          {
            id: 3,
            text: [
              <b>Tailor your resume to the role applied for:&nbsp;&nbsp;</b>,
              "While it is good to have a template for your resume, be sure to customise it for every single role you apply for, keeping in mind the skill sets that would be critical for that role. A standardized, generic resume will not stand out",
            ],
          },
          {
            id: 4,
            text: [
              <b>Keep things honest:&nbsp;&nbsp;</b>,
              "Be truthful, don’t inflate and don’t exaggerate things. Remember that any such claims can always be caught at the time of interviews and make you look unprofessional or even unethical ",
            ],
          },
          {
            id: 5,
            text: [
              <b>Don’t include unnecessary personal details:&nbsp;&nbsp;</b>,
              "Things like your age, weight, marital status, photograph, etc are unrelated to the job you are applying for, and should be removed from the resume completely ",
            ],
          },
          {
            id: 6,
            text: [
              <b>Don’t use personal pronouns:&nbsp;&nbsp;</b>,
              "Always write in third person. Avoid usage of phrases like- “I did...” or “I achieved…” ",
            ],
          },
          {
            id: 7,
            text: [
              <b>Pick a professional email address:&nbsp;&nbsp;</b>,
              "Make sure your email address is formal, and doesn’t include nicknames, random numbers, adjectives etc. ",
            ],
          },
          {
            id: 8,
            text: [
              <b>Proofread multiple times:&nbsp;&nbsp;</b>,
              "Read your resume over and over again, to make sure information flows well, ensure you have covered all the essential points, and there are no grammatical errors. ",
            ],
          },
        ],
      },
      {
        id: 3,
        data: "Keep these things in mind, and we are sure you will make a great first impression! Wish you all the best of luck! ",
      },
    ],
  },
  {
    id: 11,
    details: [
      {
        id: 1,
        data: "Artificial intelligence (AI) and machine learning are two technological transformations that are disrupting the average workplace. AI is being used for various business functions across industries, from improving consumer insights, sales forecasting, and logistics management, to HR applications like recruitment. AI-assisted recruiting has revolutionized the entire talent life cycle, and changed how HR teams recruit, engage and develop talent. According to the Society for Human Resource Management, 88% of the HR teams worldwide use AI in some capacity or the other, in their daily operations.",
      },
      {
        id: 2,
        data: "Most significantly, recruiters deploy AI-based tools to automate the recruitment process at various stages, to adopt a candidate-focused strategy and deliver a stellar onboarding experience. AI tools also help lessen the recruiting bias that is bound to come in as humans front the recruiting process. Recruiters are equipped to make better hiring choices when the bias is eliminated. ",
      },
      {
        id: 3,
        data: "For example, by using an AI/ ML algorithm to discover certain keywords in a candidate’s resume that would indicate a good applicant, candidate screening can be made smarter and faster. Recruiters can operate faster by spotting good talent seamlessly, without having to go through lengthy resumes manually. ",
      },
      {
        id: 4,
        data: "Some AI tools are now capable of going beyond simple resume screening. They can screen the candidate’s online profiles etc to provide a more comprehensive picture of the prospect’s background and skills. Better still, some organizations are now deploying tools that use AI and ML to monitor the applicant’s tone and facial expressions, to throw insights on their personality traits. Operationally speaking, recruiters are commonly utilizing AI in their applicant tracking systems (ATS), allowing them to onboard quickly, with more accurate document and background verification etc. ",
      },
      {
        id: 5,
        data: "In a nutshell, AI and ML tools are helping recruiters with the following: ",
        list: [
          {
            id: 1,
            text: "Improving the quality of candidates recruited by improving the level of scrutiny ",
          },
          {
            id: 2,
            text: "Finding the right candidates by eliminating biases and adding more scientific assessments",
          },
          {
            id: 3,
            text: "Reducing the tİme taken for various HR processes",
          },
          {
            id: 4,
            text: "Improving the candidate experience by making it seamless and strengthening the employer brand",
          },
        ],
      },
      {
        id: 6,
        data: "Like everything else in the world of technology, though, AI and ML tools cannot replace the human touch completely. Decisions such as evaluation of a cultural fit, will still require a manager or an HR leader to step in and take calls. ",
      },
      {
        id: 7,
        data: "We are excited to see this transformation in the world of HR! Are you? Tell us what AI/ML led tools in HR particularly fascinate you! ",
      },
    ],
  },
  {
    id: 12,
    details: [
      {
        id: 1,
        data: "As we step into 2024, the world of engineering and technology continues to evolve at an unprecedented pace, bringing forth exciting innovations and advancements. From artificial intelligence to sustainable engineering practices, the landscape is buzzing with transformative trends that promise to reshape industries. In this blog post, we'll explore the top emerging trends in the field of engineering and technology in 2024.",
      },
      {
        id: 2,
        list: [
          {
            id: 1,
            text: [
              <b>Artificial Intelligence (AI) Integration:&nbsp;&nbsp;</b>,
              "Artificial Intelligence continues to dominate the tech landscape, with applications ranging from automation and data analysis to machine learning and natural language processing. In 2024, we can expect AI to become even more deeply integrated into various engineering disciplines, enhancing efficiency, decision-making processes, and creating smarter, more adaptive systems.",
            ],
          },
          {
            id: 2,
            text: [
              <b>5G Technology:&nbsp;&nbsp;</b>,
              "The rollout of 5G networks is set to revolutionize communication and connectivity. In the engineering realm, this means faster data transfer, lower latency, and increased capacity for devices. Industries like Internet of Things (IoT), autonomous vehicles, and augmented reality (AR) are poised to benefit significantly from the widespread adoption of 5G technology.",
            ],
          },
          {
            id: 3,
            text: [
              <b>Sustainable Engineering:&nbsp;&nbsp;</b>,
              "With a growing emphasis on environmental sustainability, engineering practices are shifting towards eco-friendly solutions. Sustainable engineering involves designing, constructing, and maintaining infrastructure in a way that minimizes environmental impact. Expect to see an increase in the integration of renewable energy sources, green building technologies, and eco-conscious manufacturing processes.",
            ],
          },
          {
            id: 4,
            text: [
              <b>Blockchain Technology:&nbsp;&nbsp;</b>,
              "Blockchain, initially associated with cryptocurrencies, has found applications beyond finance. In engineering, blockchain is being used for secure data sharing, supply chain management, and even in the development of smart contracts. Its decentralized and tamper-resistant nature makes it an attractive solution for ensuring data integrity and transparency.",
            ],
          },
          {
            id: 5,
            text: [
              <b>Cybersecurity in Critical Infrastructure:&nbsp;&nbsp;</b>,
              "As our reliance on technology grows, so does the need for robust cybersecurity measures, especially in critical infrastructure. In 2024, we'll witness an increased focus on securing power grids, transportation systems, and other critical infrastructures against cyber threats. Engineers will play a pivotal role in designing resilient systems to safeguard against potential attacks.",
            ],
          },
          {
            id: 6,
            text: [
              <b>Edge Computing:&nbsp;&nbsp;</b>,
              "Edge computing involves processing data closer to the source rather than relying on centralized cloud servers. This trend is gaining traction in 2024, particularly in applications that require real-time processing, such as IoT devices and autonomous systems. Edge computing reduces latency, enhances efficiency, and contributes to a more responsive and reliable technological ecosystem.",
            ],
          },
          {
            id: 7,
            text: [
              <b>Biotechnology and Engineering Integration:&nbsp;&nbsp;</b>,
              "The convergence of biotechnology and engineering is opening new frontiers in healthcare, agriculture, and environmental management. From precision medicine to bioengineering, the integration of biological sciences and engineering disciplines is fostering breakthroughs that have the potential to address some of the most pressing global challenges.",
            ],
          },
          {
            id: 8,
            text: [
              <b>
                Augmented Reality (AR) and Virtual Reality (VR):&nbsp;&nbsp;
              </b>,
              "AR and VR technologies are becoming more sophisticated, offering immersive experiences in various industries. In engineering, these technologies are used for virtual prototyping, training simulations, and maintenance procedures. As the hardware becomes more accessible, we can expect increased adoption in design and engineering workflows.",
            ],
          },
        ],
      },
      {
        id: 3,
        data: "The year 2024 promises a thrilling journey for engineers and technologists as they navigate the ever-evolving landscape of emerging trends. From the integration of AI and 5G to the emphasis on sustainability and the convergence of biotechnology and engineering, these trends are not only shaping industries but also presenting new opportunities for innovation and problem-solving. As we ride the wave of technological advancements, engineers will continue to play a pivotal role in shaping the future of our increasingly interconnected world.",
      },
    ],
  },
  {
    id: 13,
    details: [
      {
        id: 1,
        data: "In the unpredictable landscape of the job market, engineering students often find themselves facing challenges that demand resilience and adaptability. A bleak job market can be disheartening, but it also presents an opportunity for students to enhance their skills and stand out in a competitive environment. In this blog post, we will explore proactive strategies that engineering students can employ to prepare themselves better for jobs during challenging times.",
      },
      {
        id: 2,
        list: [
          {
            id: 1,
            text: [
              <b>Skill Diversification:&nbsp;&nbsp;</b>,
              "While technical expertise is crucial, diversifying your skill set can set you apart. Consider acquiring soft skills such as communication, teamwork, and adaptability. Companies are increasingly valuing candidates who can seamlessly integrate technical prowess with effective communication and collaboration.",
            ],
          },
          {
            id: 2,
            text: [
              <b>Continuous Learning:&nbsp;&nbsp;</b>,
              "The field of engineering is dynamic, with advancements occurring regularly. Stay updated on industry trends, emerging technologies, and relevant certifications. Online platforms offer a plethora of courses, enabling you to acquire new skills or deepen existing ones.",
            ],
          },
          {
            id: 3,
            text: [
              <b>Networking:&nbsp;&nbsp;</b>,
              "Building a robust professional network is invaluable in any job market. Attend industry events, connect with professionals on LinkedIn, and participate in online forums. Networking provides insights into the industry, potential job openings, and can lead to mentorship opportunities.",
            ],
          },
          {
            id: 4,
            text: [
              <b>Internships and Projects:&nbsp;&nbsp;</b>,
              "Practical experience is highly valued by employers. Seek internships, co-op programs, or engage in real-world projects. These experiences not only enhance your resume but also provide you with a hands-on understanding of industry practices.",
            ],
          },
          {
            id: 5,
            text: [
              <b>Personal Projects and Portfolios:&nbsp;&nbsp;</b>,
              "Create a portfolio showcasing your projects and achievements. Whether it's a coding project, a mechanical design, or a research paper, a well-documented portfolio can serve as a tangible representation of your skills and dedication.",
            ],
          },
          {
            id: 6,
            text: [
              <b>Resume Enhancement:&nbsp;&nbsp;</b>,
              "Craft a compelling and targeted resume. Highlight your accomplishments, skills, and experiences in a way that aligns with the specific requirements of the job you're applying for. Tailoring your resume for each application can significantly increase your chances of standing out.",
            ],
          },
          {
            id: 7,
            text: [
              <b>Professional Development Programs:&nbsp;&nbsp;</b>,
              "Many organizations offer development programs or apprenticeships for recent graduates. Explore these opportunities as they often provide structured training and mentorship, paving the way for a smoother transition into the professional realm.",
            ],
          },
          {
            id: 8,
            text: [
              <b>Stay Resilient and Positive:&nbsp;&nbsp;</b>,
              "Job markets are cyclical, and setbacks are a part of the journey. Maintain a positive mindset and use challenges as opportunities for growth. Demonstrating resilience and adaptability is a trait employers highly value.",
            ],
          },
        ],
      },
      {
        id: 3,
        data: "In a bleak job market, engineering students can proactively take steps to position themselves as competitive candidates. By focusing on skill diversification, continuous learning, networking, gaining practical experience, enhancing resumes, and staying resilient, students can navigate the storm and emerge as well-prepared professionals. Remember, the journey to a successful career is a marathon, not a sprint, and the skills you develop during challenging times will undoubtedly contribute to your long-term success.",
      },
    ],
  },
  {
    id: 14,
    details: [
      {
        id: 1,
        data: "A recent study shows that globally only about 25% of the tech job applicants are female. The trend is no different in India. What is worse is that the employment gender gap grows wider as the seniority of a tech job rises. At a time when diversity is becoming a core goal for recruiters internationally, these numbers specific to the tech industry seem quite appalling.",
      },
      {
        id: 2,
        data: "According to Oxfam’s Discrimination Report, the Labour Force Participation Rate (LFPR) for women in India has rapidly declined from 42.7% in 2004-05 to a mere 25.1% in 202. This indicates a significant withdrawal of women from the workforce despite rapid economic growth during the same period. There could be many factors leading to despicable numbers. Poor access to education, societal stigmas, and a lack of financial ability to fund their education, are cited as some of the main reasons for women being left out of the tech talent pool.",
      },
      {
        id: 3,
        data: "Another reason women don’t seem to find a place in the tech industry is discrimination and gender suppression. While women make up about 34% of the IT workforce in India, 51% of them are in entry-level positions, 25% are in management and less than 1% in C-Suite positions. Furthermore, of all the tech startups in India, only 9% have female founders, as per a Nasscom report. ",
      },
      {
        id: 4,
        data: "A study by the Boston Consulting Group (BCG) has found that diversity increases the bottom line for companies. The study found that increasing the diversity of leadership teams leads to more and better innovation and improved financial performance. This is proof enough that It is high time we step in and make a change.",
      },
      {
        id: 5,
        data: "At Aspire InfoLabs, we are deeply vested in bringing the best opportunities to the most deserving candidates, irrespective of their gender, economic background, ethnicity, etc. In this regard, we are constantly innovating our hiring and training mechanisms to help change these disappointing statistics. For tech to truly fulfill the promise of leveling the playing field and creating universally relevant solutions, the representation of women in tech is key. At Aspire InfoLabs, we run the following programs for our clients to give a boost to the hiring of women in their projects-",
        list: [
          {
            id: 1,
            text: [
              <b>Campus diversity programs</b>,
              <>
                <br></br>
                We run various talent identification and training programs at
                our partner engineering colleges across the country. By way of
                Hackathons, interviews, and other selection tests, we identify
                women students who are talented and deserve that additional
                guidance and training to achieve the career of their dreams.
                <br></br>
                These women are given completely free access to our
                comprehensive talent transformation programs that are designed
                to revolutionize their technical and behavioral skills. Along
                with this, they are also given mentorship and one on one
                learning sessions with women leaders in business who have
                navigated the corporate ladders or established successful
                tech-led businesses themselves.
                <br></br>
                Furthermore, once trained, these selected talent are given
                opportunities for preferential hiring at some of the finest tech
                organizations in the world.
              </>,
            ],
          },
          {
            id: 2,
            text: [
              <b>Back to Work programs for women who took career breaks</b>,
              <>
                <br></br>
                Many women, despite having the right skillsets and experience,
                lose out due to career breaks, the most common being breaks on
                account of pregnancy and child care. Rebooting their career
                after a gap can be extremely challenging. With self-doubts and
                rapid industry and technology changes, it may seem like starting
                from scratch altogether. But with the right training and
                mentoring, women who have been on a break can be brought back to
                the workforce effectively. Aspire is proud to contribute
                directly to the careers of many such women by offering an
                exclusive program designed to help women return to the
                workforce.
                <br></br>
                Attendees of this program have access to resources that support
                their holistic development. Our comprehensive learning and
                mentorship program comprises of- exclusively curated trainings,
                experiential learning assignments, world-class online and
                offline resources for convenient learning, skill-based
                mentorship from our team of trainers (who are renowned industry
                practitioners themselves), and reporting and feedback mechanisms
                to ensure there are no gaps in learning.
              </>,
            ],
          },
          {
            id: 3,
            text: [
              <b>Diversity hiring programs for clients</b>,
              <>
                <br></br>
                As organizations wake up to the need for a more gender-balanced
                workforce and its many advantages in building great and
                productive teams, we have seen a lot of requests coming in from
                our clients for programs directed specifically towards hiring
                more women on their projects. In this regard, Aspire has played
                a critical role in making sure these goals are met efficiently.
                We work with our clients to identify the number of positions to
                be filled, define the job roles and position requirements,
                conduct tests and interviews to identify the best talent, create
                a training curriculum along with practical learning assignments,
                and execute the training programs that ensure the talent
                selected and brought onboard are perfectly equipped to deliver
                from day one.
              </>,
            ],
          },
        ],
      },
      {
        id: 6,
        data: "At Aspire, we believe that the smallest steps taken with the right intent can go a long way over time to help fix the problem of gender gap in the tech industry. At our own offices, we boast of a well balanced team- one that gets all the encouragement and support to thrive and deliver to the best of their abilities, whatever be their gender or backgrounds!",
      },
      {
        id: 7,
        data: "Reach out to our team if you’d like to address the gender skew in your teams as well!",
      },
    ],
  },
];

export const CaseStudyDetailsData = [
  {
    id: 1,
    quote:
      "Ranks, JEE Scores, CGPAs and certifications are only add-ons and play an auxiliary role. Learning ability and right attitudes build teams.",
    results: [
      {
        id: 1,
        text: "Innominds selected the entire team upon training completion, and immediately assigned them to various projects at billable positions.  After a brief induction the entire batch was fruitfully integrated into Innominds, with great performance and appreciation. ",
      },
      {
        id: 2,
        text: "The training took place and prioritized, as agreed, on one full-stack implementation technology. Post deployment, demand and requirements necessitated deployment of some of the members in a competing tech-stack. The members gracefully transitioned to the relevant technologies with agility and were productive thanks to the right attitude, mentoring and wisdom, staying true to Aspire motto of teaching “Learning to Learn” rather than just learn a tool or course. ",
      },
    ],
    details: [
      {
        id: 1,
        heading: "Background :",
        body: [
          {
            id: 1,
            text: "In early 2018, technological trends started converging towards AI, IoT and Machine Learning, Privacy and Security, and the need for building smart teams that adapt to disrupting technologies started increasing.",
          },
          {
            id: 2,
            text: "More importantly, with the exponential growth of portable smart devices and cutting-edge fields, it has now fallen on UI/UX teams to interact with the diverse user bases. This requires not just technical know-how but also flexibility, intelligence and collaboration across various domains. ",
          },
          {
            id: 3,
            text: "Innominds’ customers have repeatedly beaten competition with their excellent services offered across all stages of product development and this is only a small indication of the meticulous effort they put in building their teams.",
          },
        ],
      },
      {
        id: 2,
        heading: "Objectives :",
        body: [
          {
            id: 1,
            text: "As part of their plans to enhance their delivery capability and more significantly quality, Innomin­ds wanted to build a strong foundation layer of entry-level competency in UI/UX.",
          },
          {
            id: 2,
            text: "Innominds had the following expectations from the team-",
            list: [
              {
                id: 1,
                subText:
                  "Ability to learn nascent technologies and master them quickly",
              },
              {
                id: 2,
                subText:
                  "Sound fundamentals in both tech and delivery processes",
              },
              {
                id: 3,
                subText:
                  "Great presentation skills for ease of collaboration, both internal and external",
              },
              {
                id: 4,
                subText:
                  "Flexibility and fungibility across entire delivery life cycle",
              },
            ],
          },
          {
            id: 3,
            text: "Innominds challenged Aspire to build such a team of entry-level technologists. With quality being the main criteria and stringent timelines, this was to be vastly different from ‘generic’ hiring. Sourcing of the candidates had been done from the top 1% colleges in the country, including premier institutions like NITs and major universities. The focus was to identify the candidates with strong learning abilities and collaboration capability.",
          },
        ],
      },
      {
        id: 3,
        heading: "Strategy :",
        body: [
          {
            id: 1,
            text: "With many skills/traits to search for, multi-stage assessments (tests, personal interactions, achievements) and responsive interactions with Innominds, were done. Around 800 hopefuls were carefully scrutinized for the right fit, and 15 candidates were selected.",
          },
          {
            id: 2,
            text: "The chosen team, in order to be delivery ready, was put through a rigorous training program encompassing the following-",
            list: [
              {
                id: 1,
                subText:
                  "Strong foundation of UI/UX fundamentals and web engineering concepts",
              },
              {
                id: 2,
                subText: "Specific technology based full-stack training",
              },
              {
                id: 3,
                subText:
                  "Application of this training with hands on projects from day 1",
              },
              {
                id: 4,
                subText:
                  "Tools for code collaboration, communication and quality ",
              },
              {
                id: 5,
                subText:
                  "Mentoring from technology stalwarts for wisdom as opposed to just knowledge",
              },
            ],
          },
        ],
      },
      {
        id: 4,
        heading: "Interim milestone checks :",
        body: [
          {
            id: 1,
            text: "Aspire and Innominds successfully executed on pre-planned interim milestone checks to keep the continuous engagement, pace of learning, appropriate course corrections and individualized mentoring.",
          },
        ],
      },
    ],
  },
  {
    id: 2,
    quote:
      "We looked for the fire in the belly, and not necessarily grades. Grades in the Indian education system do not necessarily reflect the students’ learning ability.",
    results: [
      {
        id: 1,
        text: "Our client selected the entire team that successfully completed the training. They were immediately assigned to various projects, some as shadow resources and some into billable positions, after a brief induction.",
      },
      {
        id: 2,
        text: "While Aspire trained the team on a prioritized set of 3 tools, the demand came for a different BI tool. It was indeed satisfying that the team was able to learn this new tool on their own quickly and be productive This was true to the Aspire motto of teaching “Learning to Learn” rather than just learning a tool or course. ",
      },
    ],
    details: [
      {
        id: 1,
        heading: "Objectives :",
        body: [
          {
            id: 1,
            text: "As part of their plans to significantly enhance their delivery capability, our client wanted to build a strong foundation of entry level recruits. They had the following expectations from this team- ",
            list: [
              {
                id: 1,
                subText:
                  "Ability to learn new technologies and come to speed quickly",
              },
              {
                id: 2,
                subText: "Assignment readiness from fundamentals",
              },
              {
                id: 3,
                subText: "Fungibility across technologies",
              },
              {
                id: 4,
                subText:
                  "Low cost talent to bring down the overall cost of the delivery pyramid",
              },
            ],
          },
        ],
      },
      {
        id: 2,
        heading: "Strategy :",
        body: [
          {
            id: 1,
            text: "Aspire sourced the team from various leading colleges. The focus was to identify candidates with a positive attitude and a strong ability to learn. ",
          },
          {
            id: 2,
            text: "After a multi stage assessment and pre approval by the client’s recruitment team, the new recruits were put through a rigorous training program encompassing the following-",
            list: [
              {
                id: 1,
                subText:
                  "Strong foundation of programming fundamentals and business intelligence concepts",
              },
              {
                id: 2,
                subText: "Specific tool-based training",
              },
              {
                id: 3,
                subText: "Application of this training with hands on projects",
              },
              {
                id: 4,
                subText:
                  "Guided self-learning of a completely new tool.  The idea here was to build the learn to learn capability",
              },
            ],
          },
        ],
      },
      {
        id: 3,
        heading: "Interim milestone checks :",
        body: [
          {
            id: 1,
            text: "Aspire, in collaboration with our client, successfully executed on pre-planned interim milestone checks to keep the continuous engagement, pressure on learning, appropriate course corrections and individualized mentoring.",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    details: [
      {
        id: 1,
        heading: "THE TEAM THAT BUILT THE BEST HEALTH APP",
        body: [
          {
            id: 1,
            text: "Mobile Health Apps have caught on and how. The global health apps market was estimated at $38.89 billion in 2021 and is expected to rise to $314.60 billion by 2028. The real impact of all these apps though, is still to be adequately measured and quantified.",
          },
          {
            id: 2,
            text: "So, when Aspire Infolabs was brought onboard by Happily Health, to set up a tech team to build their App from scratch, we knew we had to bring together the absolute best talent to make a positive impact on the healthcare ecosystem and develop a product that stands out from the crowd.",
          },
        ],
      },
      {
        id: 2,
        heading: "HERE'S WHAT HAPPILY HEALTH CAN DO FOR YOU!",
        body: [
          {
            id: 1,
            list: [
              {
                id: 1,
                heading: "INTEGRATED HEALTH ASSESSMENTS",
                subText:
                  "Physical, Nutritional, Mental, and Lifestyle habits are all integrated. Your health assessment takes a look at each pillar of your health and analyzes your current health score.",
              },
              {
                id: 2,
                heading: "PERSONALIZED HEALTHCARE",
                subText:
                  "Working towards improving your health is a personal choice. Your health score tells the app what it needs to know to recommend regimens specific to your goals.",
              },
              {
                id: 3,
                heading: "GAMIFICATION TO KEEP IT FUN",
                subText:
                  "A community that works out together gets healthier together. Go on an adventure with friends and support each other along the way. Take up fun challenges and keep progressing!",
              },
              {
                id: 4,
                heading: "MONITOR PROGRESS AND GET REWARDED!",
                subText:
                  "We've created comprehensive dashboards for you to monitor your progress. Every step towards building habits is an achievement. Collect rewards throughout your health journey to keep you going.",
              },
            ],
          },
        ],
      },
      {
        id: 3,
        heading: "HOW DID OUR TEAM DO ALL THIS?",
        body: [
          {
            id: 1,
            list: [
              {
                id: 1,
                heading: "DATA COLLECTION, PROCESSING AND VISUALIZATION",
                subText:
                  "In order to provide the right health recommendations, the App needs to integrate data from devices such as your wearable health trackers, centralized medical data from hospitals, etc. and process all of this (big data indeed) and present it in a way that gives you actionable insights.",
              },
              {
                id: 2,
                heading: "ADVANCED BEHAVIORAL AND PREDICTIVE ANALYSIS",
                subText:
                  "Using advanced analytics techniques, the App can provide the right nudges that will help you take incremental steps to good health, in line with your personal habits. We can predict things like heart attacks and help you take corrective action too, well in time.",
              },
              {
                id: 3,
                heading: "THE WONDERS OF AI AND ML",
                subText:
                  "All of these fantastic prediction capabilities come from advanced AI and ML algorithms developed by our team of technology experts and data scientists exclusively for Happily Health.",
              },
              {
                id: 4,
                heading: "THE ZEAL TO CREATE PRODUCTS THAT MATTER!",
                subText:
                  "Yep, here is what gives us a good night's sleep... knowing that we're working on cutting edge products, learning everyday, and in our own little way, making the world a better place!",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 4,
    results: [
      {
        id: 1,
        text: "The engineers who attended our program thrived in the dynamic and data-driven learning environment. The reporting mechanism proved invaluable in achieving the following outcomes:",
        list: [
          {
            id: 1,
            subText: [
              <b>Improved Skill Acquisition:&nbsp;&nbsp;</b>,
              "Weekly reports allowed them to track individual progress and identify areas where additional support was needed. This resulted in a significant increase in skill acquisition across the group, exceeding initial training objectives",
            ],
          },
          {
            id: 2,
            subText: [
              <b>Enhanced Confidence and Motivation:&nbsp;&nbsp;</b>,
              "Transparency in the reporting process kept the customers delivery stakeholders informed about their progress and encouraged continuous improvement. This fostered a sense of ownership and motivation within the team.",
            ],
          },
          {
            id: 3,
            subText: [
              <b>Data-Driven Talent Management:&nbsp;&nbsp;</b>,
              "They leveraged the insights from the reports to make informed decisions about future deployments and skill development programs, maximizing the return on their training investment.",
            ],
          },
          {
            id: 4,
            subText: [
              <b>Stronger Team Cohesion:&nbsp;&nbsp;</b>,
              "The personalized learning paths and individual feedback provided by mentors nurtured a collaborative learning environment, further strengthening teamwork and communication within the engineering team.",
            ],
          },
        ],
      },
    ],
    details: [
      {
        id: 1,
        heading: "The Challenge :",
        body: [
          {
            id: 1,
            text: "Our client, a rapidly growing mid-level company, providing services to Fortune500 companies, of about 3000 employees worldwide, was seeking to upskill its entry-level engineering team with the in-demand MERN stack and hit the ground running. They needed a training program that could quickly equip their engineers with the necessary skills to contribute to real-world projects.",
          },
        ],
      },
      {
        id: 2,
        heading: "The Solution :",
        body: [
          {
            id: 1,
            text: "They partnered with Aspire Infolabs for an 8-week intensive MERN stack training program. The program utilized a blended learning approach with interactive lectures, hands-on projects with increasing complexity, and personalized mentorship. Additionally, Aspire implemented its unique reporting mechanism to provide the esteemed customer with detailed insights into their engineers' progress. And by the end of the program, they were furnished with actionable data that, alongside the data they had from the hiring process, helped them deploy the most appropriate candidate for the various roles they had in mind.",
            image1: CASE_STUDY_4_IMAGE_1,
          },
        ],
      },
      {
        id: 3,
        heading: "Key Features of the Reporting Mechanism :",
        body: [
          {
            id: 1,
            list: [
              {
                id: 1,
                subText: [
                  <b>Pre-training Assessment:&nbsp;&nbsp;</b>,
                  "Comprehensive self-evaluation forms and one-on-one mentor sessions identified each engineer's strengths, weaknesses, and learning goals.",
                ],
              },
              {
                id: 2,
                subText: [
                  <b>Individual Strength Gauging:&nbsp;&nbsp;</b>,
                  "Integration with the customer’s existing recruitment data provided a holistic understanding of each engineer's technical aptitude and project-solving skills.",
                ],
              },
              {
                id: 3,
                subText: [
                  <b>Weekly Progress Reports:&nbsp;&nbsp;</b>,
                  "Trainers evaluated individual performance on topics covered, skills acquired, and progress compared to peers. Detailed feedback on strengths, weaknesses, and areas for improvement was provided.",
                ],
              },
              {
                id: 4,
                subText: [
                  <b>Customized Learning Paths:&nbsp;&nbsp;</b>,
                  "Based on the data gathered, Aspire recommended personalized learning paths with additional resources and activities to address individual needs and accelerate skill development.",
                ],
              },
              {
                id: 5,
                subText: [
                  <b>Data-Driven Selection:&nbsp;&nbsp;</b>,
                  "Our transparent reporting system provided early and actionable insights, enabling the team to make informed decisions regarding final hires. This flexibility allowed for both individualized learning path adjustments and deeper evaluations of cultural and technical fit within specific teams, ensuring the right talent ultimately joined the company.",
                ],
              },
            ],
          },
        ],
      },
      {
        id: 4,
        heading: "What Our Client Had to Say :",
        body: [
          {
            id: 1,
            text: [
              `"Aspire's MERN Full Stack Boot Camp, along with their comprehensive reporting mechanism, exceeded our expectations. We are very pleased with the significant improvement in our engineers' skills and their newfound confidence in tackling real-world projects. The data-driven approach provided valuable insights that have already informed our future talent development strategies."`,
              <br></br>,
              <b>- Head of HR, APAC</b>,
            ],
          },
        ],
      },
    ],
  },
  {
    id: 5,
    details: [
      {
        id: 1,
        heading: "The Challenge :",
        body: [
          {
            id: 1,
            text: "In early 2018, technological trends started converging towards AI, IoT and Machine Learning, Privacy and Security, and the need for building smart teams that adapt to disrupting technologies started increasing. More importantly, with the exponential growth of portable smart devices and cutting-edge fields, it has now fallen on UI/UX teams to interact with the diverse user bases. This requires not just technical know-how but also flexibility, intelligence, and collaboration across various domains. Our client needed to put together a cohesive and technically skilled team to deliver on their clients’ rapidly evolving expectations, and for this, they could only trust Aspire.",
          },
        ],
      },
      {
        id: 2,
        heading: "Our Client’s Core Brief :",
        body: [
          {
            id: 1,
            text: `"Ranks, JEE Scores, CGPAs and certifications are only add-ons and play an auxiliary role. Learning ability and right attitudes build teams."`,
          },
        ],
      },
      {
        id: 3,
        heading: "Objectives Defined by the Client :",
        body: [
          {
            id: 1,
            text: "As part of their plans to enhance their delivery capability and more significantly quality, the client wanted to build a strong foundation layer of entry-level competency in UI/UX. They had the following expectations from the team being brought onboard-",
            list: [
              {
                id: 1,
                subText:
                  "Ability to learn nascent technologies and master them quickly",
              },
              {
                id: 2,
                subText:
                  "Sound fundamentals in both tech and delivery processes",
              },
              {
                id: 3,
                subText:
                  "Great presentation skills for ease of collaboration, both internal and external",
              },
              {
                id: 4,
                subText:
                  "Flexibility and fungibility across entire delivery life cycle",
              },
            ],
          },
          {
            id: 2,
            text: "The client challenged Aspire to build such a team of entry-level technologists. With quality being the main criteria and stringent timelines, this was to be vastly different from ‘generic’ hiring. Sourcing of the candidates had been done from the top 1% colleges in the country, including premier institutions like NITs and major universities. The focus was to identify the candidates with strong learning abilities and collaboration capability.",
            image1: CASE_STUDY_5_IMAGE_1,
          },
        ],
      },
      {
        id: 4,
        heading: "Aspire’s Talent Development Strategy :",
        body: [
          {
            id: 1,
            text: [
              <b>Extensive and Detailed Selection Process-</b>,
              <br></br>,
              "With many skills/traits to search for, multi-stage assessments (tests, personal interactions, achievements) and responsive interactions with all the potential talent was done. Around 800 hopefuls were scrutinized for the right fit, and 15 candidates were finally selected.",
            ],
          },
          {
            id: 2,
            text: [
              <b>Comprehensive Technical and Soft Skills Training-</b>,
              <br></br>,
              "The chosen team, to be delivery ready, was put through a rigorous training program encompassing the following",
            ],
            list: [
              {
                id: 1,
                subText:
                  "Strong foundation of UI/UX fundamentals and web engineering concepts",
              },
              {
                id: 2,
                subText: "Specific technology based full-stack training",
              },
              {
                id: 3,
                subText:
                  "Application of this training with hands on projects from day 1",
              },
              {
                id: 4,
                subText:
                  "Tools for code collaboration, communication, and quality",
              },
              {
                id: 5,
                subText:
                  "Mentoring from technology stalwarts for wisdom as opposed to just knowledge",
              },
            ],
          },
          {
            id: 3,
            text: [
              <b>Interim Milestone Checks-</b>,
              <br></br>,
              "Aspire successfully executed on pre-planned interim milestone checks to keep the continuous engagement, pace of learning, appropriate course corrections and individualized mentoring. This also helped build transparency into the training process, as the client had complete visibility into the team’s progress over time, and could offer suggestions and feedback wherever needed.",
            ],
          },
        ],
      },
    ],
  },
  {
    id: 6,
    details: [
      {
        id: 1,
        heading: "The Challenge :",
        body: [
          {
            id: 1,
            text: "Our client, the largest data centre and colocation provider for enterprise networks and cloud computing in the world, came to us with immense confidence in the tech talent that India could offer. They wanted to find the best of talent with diverse skill sets from across the country, who would lay the foundation for their India tech operations.",
          },
          {
            id: 2,
            text: "With this in mind, Aspire took up the monumental task of finding, training, and setting up an entire tech function for the client.",
          },
        ],
      },
      {
        id: 2,
        heading: "The Search :",
        body: [
          {
            id: 1,
            text: "The Aspire talent recruitment team went on a war footing searching for the smartest, most promising young engineering students graduating from the top engineering colleges, like IITs and NITs.",
          },
          {
            id: 2,
            text: "From Hackathons to HackerRank tests, a combination of challenges was given to the students to identify the most ingenious lot.",
          },
          {
            id: 3,
            text: "From the pool shortlisted, two rounds of interviews were conducted- the first one with the Aspire recruitment team, and the second (for the ones who cleared the first round) with the client’s hiring managers.",
          },
          {
            id: 4,
            text: "In both interview rounds, students were evaluated for technical skills as well as cultural and attitudinal fitment.",
          },
        ],
      },
      {
        id: 3,
        heading:
          "Key Features of the Reporting MechanismThe Onboarding and Evaluation :",
        body: [
          {
            id: 1,
            list: [
              {
                id: 1,
                subText:
                  "The selected talent was welcomed via digital onboarding as well as in-office processes and gestures. Detailed introductions, ice-breakers, and company and policy overviews were provided.",
              },
              {
                id: 2,
                subText:
                  "To get a detailed insight into the talent’s existing skillsets, we conducted one on one interviews with our team of mentors, and made the talent take self-evaluation assessments.",
              },
              {
                id: 3,
                subText:
                  "With complete clarity on where our foundations lay, we could plan personalized progress paths for each of the talent.",
              },
            ],
          },
        ],
      },
      {
        id: 4,
        heading: "The Training :",
        body: [
          {
            id: 1,
            list: [
              {
                id: 1,
                subText:
                  "Aspire’s team of mentors worked extensively with the client’s hiring managers and recruiters to deeply understand their requirements from the training curriculum.",
              },
              {
                id: 2,
                subText:
                  "The curriculum was designed keeping in mind the client’s end goals and the insights from the talents’ HackerRank scores, interviews, day zero one on one evaluations and self-assessments.",
              },
              {
                id: 3,
                subText:
                  "Then, the overall plan was divided into weekly course curriculum for the next 12 weeks, and once approved by the client, was deployed for training.",
              },
              {
                id: 4,
                subText:
                  "Students were divided into groups based on the specific skill sets they were to be trained on, and assigned mentors, who are all experienced leading industry practitioners.",
              },
              {
                id: 5,
                subText:
                  "The training used a mix of classroom methods as well as practical projects, to ensure that the learning is perfectly applicable in the real world.",
              },
              {
                id: 6,
                subText:
                  "The training also covered extensive soft skills training that not only touched upon communication and presentation skills but also valuable skills like collaboration, team work, cultural alignment, etc. This ensured holistic talent development.",
              },
            ],
            image1: CASE_STUDY_6_IMAGE_1,
          },
        ],
      },
      {
        id: 5,
        heading: "Feedback and Reporting :",
        body: [
          {
            id: 1,
            list: [
              {
                id: 1,
                subText:
                  "Aspire set up a weekly reporting mechanism for the client as well as the talent to evaluate the progress made, the strengths and areas of improvement for each attendee.",
              },
              {
                id: 2,
                subText:
                  "Individual reports were published using detailed insights from mentors at the end of every week. These reports contained details on topics covered for the week, weekly scores against class averages, mentor feedback on the hits and misses, etc.",
              },
              {
                id: 3,
                subText:
                  "Frequent one on one sessions with mentors helped the attendees reflect and seek help wherever needed.",
              },
              {
                id: 4,
                subText:
                  "The client greatly appreciated complete transparency not just in the training process but also in the attendees’ progress. The reporting mechanism allowed them to review and weigh in on areas of improvement as well, helping us course correct wherever needed, and ultimately leading to the success of the project.",
              },
            ],
            image1: CASE_STUDY_6_IMAGE_2,
            image2: CASE_STUDY_6_IMAGE_3,
          },
        ],
      },
      {
        id: 6,
        heading: "What Our Client Had to Say :",
        body: [
          {
            id: 1,
            text: [
              `"Our collaboration with Aspire proved to be immensely successful as we took our first steps into the Indian terrain. We have successfully found some incredible talent who were able to get their hands dirty and deliver on their goals right from the day they came onboard. The talent is productive, delivers the gihest standards of output, and are highly motivated. We have truly found great partners in Aspire!"`,
              <br></br>,
              <b>- Senior VP of Technology</b>,
            ],
          },
        ],
      },
    ],
  },
  {
    id: 7,
    details: [
      {
        id: 1,
        heading: "The Background :",
        body: [
          {
            id: 1,
            text: "Our client, one of the largest IT services providers in the world, reached out to us to help them on their noble mission of using their vast resources and industrial know-how to improve the quality of education imparted to engineering students in India.",
          },
          {
            id: 2,
            text: "By encouraging the use of the client’s free learning portal as an essential learning tool in every engineering student’s repertoire, Aspire InfoLabs, in collaboration with our client, set out to better train the country’s future tech talent from across the country, and prepare them for the industry’s current skill requirements.",
          },
        ],
      },
      {
        id: 2,
        heading: "Why Did We Take This Up?",
        body: [
          {
            id: 1,
            text: "Over the last fifteen years, Aspire InfoLabs has worked with some of the largest corporates and hottest startups in the world, giving us the opportunity to observe their requirements from the incoming talent from very close quarters. We have also noticed how quickly these requirements are evolving, given the pace at which technology itself is progressing as a field.",
          },
          {
            id: 2,
            text: "While engineering colleges are doing their best to keep up, given the volume of their operations, they are not able to address all these changing requirements and modify their curriculum as quickly. Also, students don’t get enough practical experience to fully imbibe their learnings in the classroom.",
          },
          {
            id: 3,
            text: "As Aspire watched this gap widen, we felt the need to make changes at the grassroots level. Our client came in with a similar intention, and their learning portal provided us just the solution we needed to make a change and help the student community.",
            image1: CASE_STUDY_7_IMAGE_1,
            image2: CASE_STUDY_7_IMAGE_2,
          },
        ],
      },
      {
        id: 3,
        heading: "Our Goals from this Partnership :",
        body: [
          {
            id: 1,
            list: [
              {
                id: 1,
                subText:
                  "Helping engineering talent from across the country get access to the best resources to aid their learning.",
              },
              {
                id: 2,
                subText:
                  "Minimize the divide brought about by a lack of access to resources, and democratize superior education.",
              },
              {
                id: 3,
                subText:
                  "By exploiting our extensive college network across the country, bridge the gap between industry expectations and academic curriculum.",
              },
              {
                id: 4,
                subText:
                  "Help talent prepare better for the hiring season by equipping themselves with the right certifications, internships, projects, etc.",
              },
              {
                id: 5,
                subText:
                  "Get the talent industry-ready from day one of their professional lives.",
              },
            ],
          },
        ],
      },
      {
        id: 4,
        heading: "How Do We Go About It?",
        body: [
          {
            id: 1,
            list: [
              {
                id: 1,
                subText:
                  "Aspire is running dedicated promotional and onboarding campaigns for the client’s learning platform on college campuses in three states- Andhra Pradesh, Telangana, and Odisha.",
              },
              {
                id: 2,
                subText:
                  "We have deployed Career Success Managers who guide the students on their onboarding and learning journeys on the client’s learning platform, and help them take the most suitable courses and certifications that will aid them in their careers.",
              },
              {
                id: 3,
                subText:
                  "Aspire is well on its mission to deliver one million hours of learning on the client’s platform  from these three states in a time period of three months.",
              },
            ],
            image1: CASE_STUDY_7_IMAGE_3,
            image2: CASE_STUDY_7_IMAGE_4,
          },
        ],
      },
      {
        id: 5,
        heading: "Results for the client :",
        body: [
          {
            id: 1,
            list: [
              {
                id: 1,
                subText:
                  "Greater on-ground promotion of the learning platform for the client.",
              },
              {
                id: 2,
                subText:
                  "Greater sign-ups and more active users on the learning platform for the client.",
              },
              {
                id: 3,
                subText:
                  "Higher consumption of content on the learning platform for the client.",
              },
              {
                id: 4,
                subText:
                  "Grassroots-level penetration of the learning platform for the client, which was earlier restricted to the larger cities.",
              },
            ],
          },
        ],
      },
      {
        id: 6,
        heading: "Results for the Tech Industry at large :",
        body: [
          {
            id: 1,
            list: [
              {
                id: 1,
                subText:
                  "Talent that is ready to deliver on their goals from day one of joining the industry.",
              },
              {
                id: 2,
                subText:
                  "Reduced investment on talent training and onboarding once the talent joins.",
              },
            ],
          },
        ],
      },
      {
        id: 7,
        heading: "What Our Client Had to Say :",
        body: [
          {
            id: 1,
            text: [
              `"We wanted to be very careful about who we partner with for this project. The paramount consideration was to collaborate with an organization that is as driven towards the larger goal of helping the engineering students across our country do better for themselves. This partnership is more of two organizations that care about the industry coming together, than anything else, and with Aspire doing the work on-ground, we have made significant strides already."`,
              <br></br>,
              <b>- CHRO</b>,
            ],
          },
        ],
      },
    ],
  },
  {
    id: 8,
    details: [
      {
        id: 1,
        heading: "The Background :",
        body: [
          {
            id: 1,
            text: "While women constitute a large part of the trained IT workforce, many of them lose out due to career breaks. Rebooting their career after a gap can be extremely challenging. With self-doubts and rapid industry and technology changes, it may seem like starting from scratch altogether. But with the right training and mentoring, women who have been on a break can be brought back to the workforce effectively.",
          },
          {
            id: 2,
            text: "Our client brought Aspire onboard to contribute directly to their Gender Diversity goals by offering an exclusive program designed to help women return to the workforce after a career break.",
          },
        ],
      },
      {
        id: 2,
        heading: "The Objectives :",
        body: [
          {
            id: 1,
            text: "Attendees of this program were to be provided with access to resources that will support their holistic development. The comprehensive learning and mentorship program would need to comprise of:",
            list: [
              {
                id: 1,
                subText: "Exclusively curated trainings.",
              },
              {
                id: 2,
                subText: "Experiential learning assignments.",
              },
              {
                id: 3,
                subText:
                  "World-class online and offline resources for convenient learning.",
              },
              {
                id: 4,
                subText:
                  "Skill-based mentorship from Aspire’s team of trainers (who are renowned industry practitioners themselves)",
              },
              {
                id: 5,
                subText:
                  "Reporting and feedback mechanisms to ensure there are no gaps in learning.",
              },
            ],
          },
        ],
      },
      {
        id: 3,
        heading: "The Strategy and Execution :",
        body: [
          {
            id: 1,
            list: [
              {
                id: 1,
                subText:
                  "The client identified the number of positions to be filled.",
              },
              {
                id: 2,
                subText:
                  "The client partially created the talent pool, and also collaborated with Aspire InfoLabs to find the right talent for some positions.",
              },
              {
                id: 3,
                subText:
                  "Tests and Interviews were conducted to freeze on the best candidates.",
              },
              {
                id: 4,
                subText:
                  "The course curriculum and practical learning program was developed in tandem.",
              },
              {
                id: 5,
                subText:
                  "The training program was executed by Aspire InfoLabs with periodic reporting for the client to monitor progress.",
              },
              {
                id: 6,
                subText:
                  "Based on the talent’s performance in the back to work program, final evaluations were done and offers were rolled out.",
              },
            ],
          },
        ],
      },
      {
        id: 4,
        heading: "The Results :",
        body: [
          {
            id: 1,
            list: [
              {
                id: 1,
                subText:
                  "We designed the most ideal course content and practical assignments for the attendees, based on the specific requirements laid out by the client.",
              },
              {
                id: 2,
                subText:
                  "Our training programs cover the most sought after skills in the tech job market, such as Java, .Net, DevOps, Testing, Full Stack, Cloud Computing, Big Data, AI/ML, IoT/ Embedded System.",
              },
              {
                id: 3,
                subText: [
                  "The attendees not only saw a complete rehash of their technical prowess, they were also thrilled with the soft skills trainings and 1:1 sessions they got to attend with women leaders of the industry, One of our attendees said after the training-",
                  <br></br>,
                  `“My interactions with other women who have excelled as tech leaders, despite facing the same challenges that forced me to take a break from work- the pressures of family and children- helped me see myself in a new light. If they can do it, so can I. The training has helped me find myself again and give myself another chance to achieve my dreams. Thank you, team Aspire. You have changed the course of my life!`,
                ],
              },
            ],
            image1: CASE_STUDY_8_IMAGE_1,
          },
        ],
      },
      {
        id: 5,
        heading: "What Our Client Had to Say :",
        body: [
          {
            id: 1,
            text: [
              `"Gender diversity is a critical goal for the HR team. We want to be truly inclusive, not just by hiring women when we go to campus as a checklist item. We want to go deeper than that. We want women to feel like they can have good careers and also focus on their families and children. We want to provide a nurturing environment to all our women employees. Therefore, running programs like these that empower women who have taken a break, to come back when they are ready with full force, was a fabulous idea by Aspire. Having worked with them previously, we fully trusted that they were the only ones who could bring this wonderful and thoughtful initiative to life. And boy were we right! What an honor it has been to see our beloved employees come back and take their teams by storm! Thank you, Aspire. You are truly raising the bar!"`,
              <br></br>,
              <b>- CHRO</b>,
            ],
          },
        ],
      },
    ],
  },
  {
    id: 9,
    details: [
      {
        id: 1,
        heading: "The Background :",
        body: [
          {
            id: 1,
            text: "Aspire believes in investing in the young talent that is graduating from engineering colleges across the country. Given the right training and opportunities, it is not just talent from the IITs and NITs that can do well, but also students from Tier 2 and Tier 3 colleges who can shine in their careers. We are fully vested in providing an equal opportunity and voice to students from these colleges, that often do not get the best avenues for their students.",
          },
          {
            id: 2,
            text: "Aspire works with Tier 2 and Tier 3 colleges in Andhra Pradesh, Telangana, and Odisha, to constantly engage with their student community and provide different interventions to further their career goals. Not only does this help the students be prepared for their jobs, but also helps us build a reliable, trained talent farm to meet the immediate hiring requirements of our clients, some of the largest tech MNCs in the world.",
          },
        ],
      },
      {
        id: 2,
        heading: "The Objectives :",
        body: [
          {
            id: 1,
            text: "Our key objectives from these ongoing college engagements are:",
            list: [
              {
                id: 1,
                subText:
                  "Helping the students to identify their interests, strengths and areas of improvement.",
              },
              {
                id: 2,
                subText:
                  "Giving students the resources they need to address their areas of improvement.",
              },
              {
                id: 3,
                subText:
                  "Provide students with practical experience via internships and industry facetime",
              },
              {
                id: 4,
                subText:
                  "Help colleges improve their recruiter profiles by bringing better hiring opportunities for their students.",
              },
              {
                id: 5,
                subText:
                  "Helping recruiters quickly close their talent requirements from our well-trained talent farms.",
              },
            ],
          },
        ],
      },
      {
        id: 3,
        heading: "The Strategy :",
        body: [
          {
            id: 1,
            text: "We use a combination of the following engagements:",
            list: [
              {
                id: 1,
                subText:
                  "Hackathons and other projects to challenge the students and force them to think deeper, including prizes and internship opportunities for the winning students.",
              },
              {
                id: 2,
                subText:
                  "Industry visits and speaker sessions with business leaders to broaden their horizons and get inspired to push themselves harder.",
              },
              {
                id: 3,
                subText:
                  "Internship programs at Aspire as well as on our clients’ projects for practical experience that bridges the gap between academic learning and real on-the-job performance abilities.",
              },
              {
                id: 4,
                subText:
                  "Free training programs (such as tailor-made summer training programs) for students from these colleges that help the students polish their technical skills and be prepared to meet dynamic recruiter expectations.",
              },
              {
                id: 5,
                subText:
                  "Additional mentorship programs for 1:1 guidance to students from the training programs who show the most potential, along with fast tracked placement opportunities at some of the largest and most sought-after tech companies.",
              },
            ],
            image1: CASE_STUDY_9_IMAGE_1,
          },
        ],
      },
      {
        id: 4,
        heading: "The Results :",
        body: [
          {
            id: 1,
            text: "For Students and Colleges-",
            list: [
              {
                id: 1,
                subText:
                  "Over 5000 students trained via various engagements in the last one year.",
              },
              {
                id: 2,
                subText: "10 Hackathons conducted in the last one year.",
              },
              {
                id: 3,
                subText:
                  "100+ internships offered to students at Aspire and many more with our clients.",
              },
              {
                id: 4,
                subText:
                  "Summer training programs conducted at 10 campuses in the last one year.",
              },
            ],
          },
          {
            id: 2,
            text: "For Recruiters-",
            list: [
              {
                id: 1,
                subText:
                  "3 of our top clients (global MNCs that are some of the most desirable recruiters among engineering students) chose to pick talent from the trained pool identified by us, instead of going to IITs and NITs.",
              },
              {
                id: 2,
                subText:
                  "Not only did this help them get equally good talent at better packages, that talent came to them fully trained and were able to deliver on the job from day one.",
              },
              {
                id: 3,
                subText:
                  "This model for hiring has been so successful that two of our clients have gone ahead and established Centers of Excellence in collaboration with Aspire that are constantly finding and training new talent for their upcoming requirements.",
              },
            ],
          },
        ],
      },
      {
        id: 5,
        heading: "What Our Client Had to Say :",
        body: [
          {
            id: 1,
            text: [
              `"Given the magnitude of work that we have now started delegating to our India offices, it was becoming increasingly important to find a hiring model that helps us get the best talent on board, very quickly. Traditional models of campus hiring are time consuming and often not the most effective. With Aspire’s talent farms as a primary source of talent, we are bringing in highly trained resources at more competitive packages. This is a Just-In-Time hiring model that has changed the game for us."`,
              <br></br>,
              <b>- CHRO</b>,
            ],
          },
        ],
      },
      {
        id: 6,
        heading: "What Our Talent Has to Say :",
        body: [
          {
            id: 1,
            text: `"We have always felt the lack of opportunities because we couldn’t crack the IITs. Even though our college and faculty strive very hard to ensure we are as good resources as the students from the grade-A colleges, we never saw the best of companies coming to our college to hire. With Aspire’s interventions, like company-sponsored hackathons, guest lectures, and internship opportunities, we feel we are finally at par with the students graduating from top colleges. We feel motivated and equipped now, to prove that we are as good, if not better! Thank you, team Aspire for believing in us. We are truly grateful to our mentors!"`,
          },
        ],
      },
    ],
  },
];

export const NewsLetterDetailsData = [
  {
    id: 1,
    details: [
      {
        id: 1,
        data: "Sometimes, you meet people who change the course of your life. When Prudhvi Madasu was introduced to Aspire’s Founder, Srinivas Patnaik, little did he know that this gem of a person, and his talent transformation program at Aspire, is going to rewrite his career completely.",
      },
      {
        id: 2,
        data: "Read this chat with Prudhvi, now an analyst at Bank of America, who talks about his career progression thus far, his learnings along the way, and his advice to students who are setting out to look for their first corporate assignments.",
      },
      {
        id: 3,
        heading:
          "Prudhvi, thank you so much for taking the time out for this interview. Please tell us a little bit about Prudhvi before Aspire.",
      },
      {
        id: 4,
        data: "Thank you so much, Isha and Swati, for speaking with me. I generally like to divide my life into a before Aspire and after Aspire period, because Aspire really was the turning point in my life.",
      },
      {
        id: 5,
        data: "I completed my graduation from Karimnagar, a city 160 km away from Hyderabad. From my 3rd semester, I was interested in programming. I learned different technologies such as Java, Testing, etc., but like a hobby. I didn’t have any proper instruction or guidance. After graduation, I came to Hyderabad looking for a job. I met several consultants, who mostly were very expensive and couldn’t help me with any decent inroads.",
      },
      {
        id: 6,
        heading:
          "How did you learn about Aspire? Why did you decide to join the program?",
      },
      {
        id: 7,
        data: "As I was struggling to get a good role, I got to hear about Aspire from an uncle. He spoke very highly of Aspire’s courses, so I decided to look them up. When I walked into their office, I realized, that Aspire’s approach towards talent management is very different. They were not pushy. They talked about how they will prepare us not just with technical skills and training on multiple technologies, but also on the application of our knowledge, and the ability to pick up new technologies, quickly. The focus was on making students industry ready and adaptable in various situations. It was more like building life-skills, rather than just one programming language. Of course, I ended up learning some ten programming technologies, while at Aspire!",
      },
      {
        id: 8,
        heading: "How was your experience and learning at Aspire?",
      },
      {
        id: 9,
        data: "Aspire was absolutely brilliant. The trainers focus on making us well-educated, and not just well-trained. They didn’t straight-up give us tall claims of getting jobs etc. They took a screening interview and said that I will be invited for another interview from an MNC if I make the cut. Only if they select me, will I get the chance to undergo the training. So, in that sense, there was some surety of a job, if we made it to the program.",
      },
      {
        id: 10,
        data: "Once I got picked by Dell, we started our training, which was about 3 months long. We would be in the classroom from 6 am till 9 pm. It was very intensive. We got all the support we ever needed, in terms of infrastructure, instructions, and even mentorship and career counseling. Soft skills were also given equal importance.",
      },
      {
        id: 11,
        data: "After our training, Dell took another three rounds of interviews, to make sure we all have risen to their stringent quality standards. All of us made it and started our very first jobs, fully prepared for what is in store.",
      },
      {
        id: 12,
        heading: "Sounds like a very stressful program!",
      },
      {
        id: 13,
        data: "Actually no. We had a very fun, friendly environment. The entire batch became good friends. We used to learn and have fun together. We collectively pushed our limits to go beyond just the 2-3 high demand technologies, to pick up everything we could. We had very good guidance from our teachers, who nudged us in the right direction in our careers.",
      },
      {
        id: 14,
        heading:
          "After you joined, did you feel better prepared than the other new joiners?",
      },
      {
        id: 15,
        data: "Yes, absolutely. For the first 8 months, we were all in a common process. But I realized that most of the candidates who joined from other places had good domain knowledge of 1 or 2 technologies. Aspire recruits were armed with many more technologies- we knew 3 EDLs, 3 reporting tools, 4 databases. We were ready to deploy resources, no matter what the process, be it reporting, data extraction and cleaning, or anything else. Whenever there was a sudden requirement for any team, Aspire recruits were the go-to guys, because we already had practical knowledge on multiple things.",
      },
      {
        id: 16,
        heading:
          "What were the key life lessons you picked up during your stint at Aspire, that you still hold close to your heart?",
      },
      {
        id: 17,
        data: "Two things- one is discipline. We had to be in our classrooms at 6 am, no matter what. Second- we learned how to push ourselves beyond what we thought we were capable of achieving. If we were falling behind on things, we voluntarily took the keys for the office, and stayed back and caught up.",
      },
      {
        id: 18,
        heading: "What does the future hold for Prudhvi?",
      },
      {
        id: 19,
        data: "Currently, I’m working on learning about ML. I’m undergoing training. I want to be a data scientist. I’m thankful to Aspire, actually, for pushing me towards data and AI/ML. This is the place to be, this is where all the action is. My teachers at Aspire mentored me and encouraged me to take up ML as my career choice.",
      },
      {
        id: 20,
        heading:
          "What advice would you like to give to Aspire’s current and future students?",
      },
      {
        id: 21,
        data: "I believe Aspire’s programs are holistic and completely thought-through. I’d say just trust in Aspire, and let them do their magic. Work hard, believe in the curriculum Aspire builds, and placements will be a breeze.",
      },
    ],
  },
  {
    id: 2,
    details: [
      {
        id: 1,
        data: "Pranav is Senior Technical Consultant for a healthcare product being developed by a leading Digital Transformation Agency. A person who runs high on talent and productivity, Pranav wears multiple hats in his organization. From the role of a senior consultant, to client manager, and also a mentor for his team, Pranav’s versatility and go-getter attitude has proved to be an asset for his employer.",
      },
      {
        id: 2,
        data: "An expert in front end technology and AWS cloud-based services, Pranav is a quick learner and has delivered value and grown in his career and knowledge, something we, at Aspire, feel very proud of. He is truly a great example of Aspire’s “learning to learn” philosophy.",
      },
      {
        id: 3,
        data: "Read on to learn more about his journey.",
      },
      {
        id: 4,
        heading:
          "How did you learn about Aspire? Why did you decide to join the program?",
      },
      {
        id: 5,
        data: "My first interaction with Aspire was in 2010. I was contacted by their HR team, and they mentioned they were looking for good talent to join a leading IT company. I decided to give it a go. I went through three rounds of screening- a basic assessment, followed by a group discussion and 1:1 interview with the HR team of the IT company. ",
      },
      {
        id: 6,
        data: "The reason I was keen to go through the Aspire process was that they were giving me an opportunity to pick up a challenging technical role. Before Aspire, I was working on a non-technical process for Google Maps. That wasn’t really utilizing my degree nor fueling my passion for technology and its possibilities.",
      },
      {
        id: 7,
        data: "The opportunity Aspire was offering me was cutting edge technology. Mobile tech was still in its nascent stages back in 2010, and Aspire helped me pick up this specialty that became a hot skill in time. I got to be part of core product development as opposed to a client servicing profile.",
      },
      {
        id: 8,
        heading:
          "How was your experience and learning at Aspire? Why did you pick Aspire over the others?",
      },
      {
        id: 9,
        data: "We went through a rigorous training of 45 days. It was a great mix of people from different backgrounds, and also a great prelude to an actual work environment. Since the entire batch was joining the same client, it was good to get to know each other and become friends in advance. ",
      },
      {
        id: 10,
        data: "One thing that stood out for me was the way we were pushed to think out of the box, and expand our knowledge by challenging ourselves… the approach was focused on making us independent learners who think and solve their own problems, not be spoon-fed. Everyone came up with their own solutions to real world problem statements, and together we grew with these brainstorming sessions.",
      },
      {
        id: 11,
        data: "Soft skill sessions on personality development, presentation skills, etc. were also very interesting. Though fewer than the technical sessions, we really enjoyed them. ",
      },
      {
        id: 12,
        heading: "After you joined Hurix, how has your journey been?",
      },
      {
        id: 13,
        data: "The journey has been quite fulfilling, in terms of self-progression and learning. The fact that the initial products we developed for the clients still find use in the market, is great. We built long term solutions, as against something that became redundant fast.",
      },
      {
        id: 14,
        data: "I’ve been able to move around and contribute to multiple products, so I’ve learnt tremendously. Now I also manage teams. My career has progressed the way I envisioned it. I’m still closely associated with tech but I also am building my management skills. ",
      },
      {
        id: 15,
        heading:
          "What were the key life lessons you picked up during your stint at Aspire, that you still hold close to your heart?",
      },
      {
        id: 16,
        data: "Aspire taught me to focus on the big picture, to accept challenges and keep moving forward to achieve the long-term goal. Aspire gave me the confidence to follow my heart, not just to run after money but a satisfying career, and that advice has held me in good stead. ",
      },
      {
        id: 17,
        heading: "What does the future hold for Pranav? ",
      },
      {
        id: 18,
        data: "I’m working with healthcare startups to give shape to their vision & also bring immediate value and impact to the end users. I would like to contribute towards building interesting products, and be a part of their growth story, knowing that I’ve had a part to play in their journey. ",
      },
      {
        id: 19,
        heading:
          "What advice would you like to give to Aspire’s current and future students?",
      },
      {
        id: 20,
        data: "Always choose a cutting-edge technology and work whole heartedly towards picking that up, even if that doesn’t fit into your short-term goals. It will bring you to where you wanted to be 😊",
      },
    ],
  },
];

export const SocialIcons = [
  {
    name: "Connect with us on our Facebook page for regular updates about our work ",
    image: FACEBOOK_ICON,
    url: FACEBOOK_URL,
  },
  {
    name: "Connect with us on our Twitter page for regular updates about our work",
    image: TWITTER_ICON,
    url: TWITTER_URL,
  },
  {
    name: "Connect with us on our LinkedIn page for regular updates about our work ",
    image: LINKEDIN_ICON,
    url: LINKEDIN_URL,
  },
];

export const BlogIcons = [
  {
    name: "Connect with us on our Facebook page for regular updates about our work ",
    image: BLOG_FACEBOOK_ICON,
    url: FACEBOOK_URL,
  },
  {
    name: "Connect with us on our Twitter page for regular updates about our work",
    image: BLOG_TWITTER_ICON,
    url: TWITTER_URL,
  },
  {
    name: "Connect with us on our LinkedIn page for regular updates about our work ",
    image: BLOG_LINKEDIN_ICON,
    url: LINKEDIN_URL,
  },
];
