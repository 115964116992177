export const contentActionTypes = {
  FETCH_REQUEST: "FETCH_REQUEST",
  FETCH_SUCCESS: "FETCH_SUCCESS",
  FETCH_FAILURE: "FETCH_FAILURE",
  POST_REQUEST: "POST_REQUEST",
  POST_SUCCESS: "POST_SUCCESS",
  POST_FAILURE: "POST_FAILURE",
  EDIT_REQUEST: "EDIT_REQUEST",
  EDIT_SUCCESS: "EDIT_SUCCESS",
  EDIT_FAILURE: "EDIT_FAILURE",
  DELETE_REQUEST: "DELETE_REQUEST",
  DELETE_SUCCESS: "DELETE_SUCCESS",
  DELETE_FAILURE: "DELETE_FAILURE",
};
