import {
  ROUTE_PRODUCT_SERVICES,
  ROUTE_TALENT_AND_TRAINING_SERVICES,
} from "../../shared/constants/route.const";
import {
  POWERFUL_TEAMS,
  TALENT_ATTRACTION,
  TALENT_RETENTION,
  TALENT_UPSKILLING,
} from "../../shared/images/commonImages";
import {
  BLOG_1,
  BLOG_2,
  BLOG_3,
  BLOG_4,
  BLOG_5,
  BLOG_6,
  BLOG_7,
  BLOG_8,
  BLOG_9,
  BLOG_10,
  BLOG_11,
  BLOG_12,
  BLOG_13,
  BLOG_14,
  BLOG_COVER_6,
  BLOG_COVER_7,
  BLOG_COVER_8,
  BLOG_COVER_12,
  BLOG_COVER_13,
  BLOG_COVER_14,
} from "../../shared/images/publicationImages";

export const PAGE_HEAD = {
  heading: "Driving the Innovations of Tomorrow",
};

export const CLIENTS_LIST = {
  title: "Clients who trust us",
};

export const HOME_SERVICES = {
  text: "With ever-changing technological advancements on the one hand, and shortage of capable, trained talent on the other, IT organizations are increasingly struggling with the complexity of the challenges that technical workforce management brings. What they need are holistic strategies and expertise in building teams that are technically equipped to dive right in, build stellar products and solutions at breakneck speeds, and help their employers grow phenomenally.",
};

export const TAKE_ACTION = {
  actionInfo: "Transform your Workforce Strategy",
  descriptionInfo:
    "From new hire requirements, to building experienced teams that can seamlessly integrate with your existing workforce and deliver on expectations, Aspire Infolabs has got you covered.",
};

export const TALENT_INFO = {
  title:
    "Aspire Infolabs provides holistic talent solutions that address the entire lifecycle of IT Talent, and help build great products.",
  description:
    "We transform the way our clients hire, manage, and develop their talent, today and in the future. Guided by our expertise in talent management, we provide integrated solutions across the entire talent lifecycle.",
  talentsInfo: [
    {
      image: TALENT_ATTRACTION,
      title: "Talent Attraction and Acquisition",
    },
    {
      image: TALENT_UPSKILLING,
      title: "Talent Upskilling and Development",
    },
    {
      image: TALENT_RETENTION,
      title: "Talent Retention at Scale",
    },
    {
      image: POWERFUL_TEAMS,
      title: "Powerful Teams that Drive Client Success",
    },
  ],
};

export const SERVICES_INFO = {
  title: "Our Services",
  ourServicesData: [
    {
      title: "Product Architecture and Engineering Teams",
      subText1:
        "At Aspire Infolabs, we build, run and manage cutting edge product & engineering teams for Fortune 500 clients and start-ups across the globe.",
      subText2:
        "We are the leaders in building cloud, data science, IoT & enterprise software teams. We establish and run centres of excellence to provide product/solution design & development services for clients in the retail, supply chain, manufacturing, logistics, telecom, utilities, energy, oil & gas, aviation, & fintech industries. ",
      path: ROUTE_PRODUCT_SERVICES.path,
    },
    {
      title: "Fresher Hiring and Training Solutions",
      subText1:
        "With over 15,000 successful placements and a 100% client retention rate, Aspire InfoLabs is the most trusted talent recruitment and training partner for the world’s largest IT companies.",
      subText2:
        "Our digital talent enhancement programs have established the gold standards of excellence in finding and training innovative, empowered and result-driven talent that is assignment ready.",
      path: ROUTE_TALENT_AND_TRAINING_SERVICES.path,
    },
  ],
};

export const INSIGHTS_INFO = {
  title: "Insights",
  ourInsightsData: [
    {
      id: 1,
      title:
        "Aspire Blog: The Art of Learning to Learn- A Valuable Life Lesson",
      url: "the-art-of-learning-to-learn",
      image: BLOG_1,
      cover: BLOG_1,
      description: "The Art of Learning to Learn – A Valuable Life Lesson",
      backgroundColor: "#edd107",
      color: "black",
      author: "Ravi Pattamatta",
      date: "2021-03-12T06:53:03.999Z",
      category: "category",
    },
    {
      id: 2,
      title: "Aspire Blog: Life Lessons from an IT Veteran",
      url: "life-lessons-from-an-IT-veteran",
      image: BLOG_2,
      cover: BLOG_2,
      description: "Life Lessons from an IT Veteran",
      backgroundColor: "#edd107",
      color: "black",
      author: "Amit Sahoo",
      date: "2021-03-30T06:53:03.999Z",
      category: "category",
    },
    {
      id: 3,
      title: "Aspire Blog: Campus Hiring- Then and Now",
      url: "campus-hiring-then-and-now",
      image: BLOG_4,
      cover: BLOG_4,
      description: "Campus Hiring: Then and Now",
      backgroundColor: "#edd107",
      color: "black",
      author: "Amit Sahoo",
      date: "2021-04-07T06:53:03.999Z",
      category: "category",
    },
    {
      id: 4,
      title: "Aspire Blog: The Importance of Belonging",
      url: "the-importance-of-belonging",
      image: BLOG_3,
      cover: BLOG_3,
      description: "The Importance of Belonging",
      backgroundColor: "#edd107",
      color: "black",
      author: "Betty Patnaik",
      date: "2021-03-31T06:53:03.999Z",
      category: "category",
    },
    {
      id: 5,
      title: "Aspire Blog: 5 things to do to secure a successful future",
      url: "top-5-things-to-do-to-secure-a-successful-future",
      image: BLOG_5,
      cover: BLOG_5,
      description: "Top 5 things to Do to Secure A Successful Future",
      backgroundColor: "#edd107",
      color: "black",
      author: "Amit Sahoo",
      date: "2020-12-08T06:53:03.999Z",
      category: "category",
    },
    {
      id: 6,
      title: "A Day in the life of a Data Scientist",
      url: "a-day-in-the-life-of-a-data-scientist",
      image: BLOG_6,
      cover: BLOG_COVER_6,
      description: "A Day in the life of a Data Scientist",
      backgroundColor: "#edd107",
      color: "black",
      author: "Santoshi Rakshita",
      date: "2022-02-25T00:00:00.000Z",
      category: "category",
    },
    {
      id: 7,
      title:
        "Opting to Become a Full Stack Developer- What to Expect and How to Prepare",
      url: "opting-to-become-a-full-stack-developer",
      image: BLOG_7,
      cover: BLOG_COVER_7,
      description:
        "Opting to Become a Full Stack Developer- What to Expect and How to Prepare",
      backgroundColor: "#edd107",
      color: "black",
      author: "Prateek Mishra",
      date: "2022-03-01T00:00:00.000Z",
      category: "category",
    },
    {
      id: 8,
      title:
        "Kickstarting your career the right way: 7 lessons you’ll cherish all your life",
      url: "kickstarting-your-career-the-right-way",
      image: BLOG_8,
      cover: BLOG_COVER_8,
      description:
        "Kickstarting your career the right way: 7 lessons you’ll cherish all your life",
      backgroundColor: "#edd107",
      color: "black",
      author: "Isha Gupta",
      date: "2022-03-10T00:00:00.000Z",
      category: "category",
    },
    {
      id: 9,
      title: "Navigating Through an Uncertain Job Market",
      url: "navigating-through-job-market",
      image: BLOG_9,
      cover: BLOG_9,
      description: "Navigating Through an Uncertain Job Market",
      backgroundColor: "#edd107",
      color: "black",
      author: "Isha Gupta",
      date: "2022-03-10T00:00:00.000Z",
      category: "category",
    },
    {
      id: 10,
      title: "Checklist for a Well-Written Resume",
      url: "checklist-well-written-resume",
      image: BLOG_10,
      cover: BLOG_10,
      description: "Checklist for a Well-Written Resume",
      backgroundColor: "#edd107",
      color: "black",
      author: "Isha Gupta",
      date: "2022-03-05T00:00:00.000Z",
      category: "category",
    },
    {
      id: 11,
      title: "The Impact of AI and ML on Hiring Practices",
      url: "impact-ai-ml-hiring",
      image: BLOG_11,
      cover: BLOG_11,
      description: "The Impact of AI and ML on Hiring Practices",
      backgroundColor: "#edd107",
      color: "black",
      author: "Isha Gupta",
      date: "2022-03-10T00:00:00.000Z",
      category: "category",
    },
    {
      id: 12,
      title:
        "Riding the Wave: Top Emerging Trends in Engineering and Technology in 2024",
      url: "riding-the-wave",
      image: BLOG_12,
      cover: BLOG_COVER_12,
      description:
        "Riding the Wave: Top Emerging Trends in Engineering and Technology in 2024",
      backgroundColor: "#edd107",
      color: "black",
      author: "Isha Gupta",
      date: "2024-04-26T00:00:00.000Z",
      category: "category",
    },
    {
      id: 13,
      title:
        "Navigating the Job Market Storm: A Guide for Engineering Students",
      url: "navigating-the-job-market-storm",
      image: BLOG_13,
      cover: BLOG_COVER_13,
      description:
        "Navigating the Job Market Storm: A Guide for Engineering Students",
      backgroundColor: "#edd107",
      color: "black",
      author: "Isha Gupta",
      date: "2024-04-26T00:00:00.000Z",
      category: "category",
    },
    {
      id: 14,
      title:
        "Gender Diversity in the Indian Technology Sector and How We Are Doing Our Bit to Fix It",
      url: "gender-diversity-in-the-indian-technology-sector",
      image: BLOG_14,
      cover: BLOG_COVER_14,
      description:
        "Gender Diversity in the Indian Technology Sector and How We Are Doing Our Bit to Fix It",
      backgroundColor: "#edd107",
      color: "black",
      author: "Isha Gupta",
      date: "2024-04-26T00:00:00.000Z",
      category: "category",
    },
  ],
  buttonText: "See More",
};
