import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import useMobileView from "../../../../../../shared/utils/hooks/useMobileView";
import useTabView from "../../../../../../shared/utils/hooks/useTabView";

const usePublicationsCaseStudyStyles = makeStyles((theme) => ({
  description: {
    fontSize: 20,
    lineHeight: 1.5,
  },
  orderedList: {
    paddingLeft: 25,
    marginTop: theme.spacing(2),
  },
  sectionHeaders: {
    fontWeight: 700,
    margin: theme.spacing(5, 0),
  },
  subText: {
    marginBottom: theme.spacing(2),
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const CaseStudyContent = ({ details }) => {
  const classes = usePublicationsCaseStudyStyles();
  const isMobileView = useMobileView();
  const isTabView = useTabView();

  return (
    <>
      {details.map((el1, index1) => (
        <>
          <Typography
            variant="h3"
            className={classes.sectionHeaders}
            key={index1}
          >
            {el1.heading}
          </Typography>
          {el1.body.map((el2, index2) => (
            <Typography
              variant="subtitle1"
              className={classes.description}
              key={index2}
            >
              {el2.text}
              <ol className={classes.orderedList}>
                {el2.list
                  ? el2.list.map((el3, index3) => (
                      <li key={index3}>
                        <b>{el3.heading}</b>
                        {el3.subText && (
                          <Typography
                            variant="subtitle1"
                            className={classes.subText}
                          >
                            {el3.subText}
                          </Typography>
                        )}
                      </li>
                    ))
                  : null}
              </ol>
              <div
                className={classes.imageContainer}
                style={{
                  flexDirection: isMobileView || isTabView ? "column" : "row",
                }}
              >
                {el2.image1 && (
                  <img
                    src={el2.image1}
                    alt="blog_image"
                    style={{
                      objectFit: "contain",
                      width: isMobileView || isTabView ? "100%" : "50%",
                      maxHeight:400,
                    }}
                  />
                )}
                {el2.image2 && (
                  <img
                    src={el2.image2}
                    alt="blog_image"
                    style={{
                      objectFit: "contain",
                      width: isMobileView || isTabView ? "100%" : "50%",
                      maxHeight:400,
                    }}
                  />
                )}
              </div>
            </Typography>
          ))}
        </>
      ))}
    </>
  );
};

export default CaseStudyContent;
